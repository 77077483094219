import React, { useState, useEffect } from 'react'
import smoothscroll from 'smoothscroll-polyfill'
import GamesService from 'services/GamesService'
import ContentService from 'services/ContentService'
import Header from '@/layouts/sections/Header'
import DKRouter from '@/routers'
import Footer from '@/layouts/sections/Footer/Footer'
import { NotificationContainer } from 'react-notifications'
import Modals from '@/layouts/sections/Modals'
import styled from 'styled-components'
import device from 'utils/mediaQueries'
import {
    FULL_HEADER_SIZE_DESKTOP,
    FULL_HEADER_SIZE_MOBILE,
    TOPBAR_DESKTOP_HEIGHT,
} from 'configs/ui'
import NavigationMenu from '@/layouts/sections/NavigationMenu'
import Index from '@/layouts/sections/BottomLogInArea'
import GEOBlock from 'features/GEOBlock'
import { initGEOData } from 'services/GeoService'
import { useHistory, useLocation } from 'react-router-dom'
import Wrapper from 'ui/Wrapper'
import { BACKGROUNDS, COLORS } from 'themes'
import Icon from 'ui/Icon'
import scrollIcon from 'icons/scrolltop.svg'
import Topbar from '@/layouts/sections/Topbar'
import { ModalService } from 'services/ModalService'
import UserService from 'common/Services/UserService'
import UserAdapter from 'common/adapters/UserAdapter'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import UrlHistoryService from 'services/UrlHistoryService'
import MinimizeScreen from 'features/MinimizeScreen'
import { distinctUntilChanged } from 'rxjs/operators'
import MetaTags from 'react-meta-tags'
import SubMenu from '@/layouts/sections/NavigationMenu/SubMenu'
import { SubMenuData } from 'configs/menu-items'
import ReactTooltip from 'react-tooltip'
import LastLoginPopup from 'features/Login/LastLoginPopup'

const PAGES_WITHOUT_MARGIN = ['fun', 'money']

const PageWrapper = styled.div`
    flex: 1;
    padding-top: ${({ top }) => (top ? top : FULL_HEADER_SIZE_DESKTOP + 'px')};
    //transition: all 0.5s ease-in-out;
    @media screen and ${device.md} {
        padding-top: ${FULL_HEADER_SIZE_MOBILE}px;
    }

    @media screen and ${device.sm} {
        padding-top: ${({ offset }) =>
            offset
                ? `${FULL_HEADER_SIZE_MOBILE}px`
                : `calc(${FULL_HEADER_SIZE_MOBILE}px + 68px)`};
    }
`
const ScrollUp = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${BACKGROUNDS.white};
    box-sizing: border-box;
    position: fixed;
    bottom: 70px;
    right: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;

    ${Icon} {
        transform: rotate(180deg);
    }

    @media screen and ${device.lg} {
        right: 20px;
        bottom: ${({ bottom }) => (bottom ? bottom : '150px')};
    }
`

export const TooltipStyled = styled(ReactTooltip)`
    font-size: 14px !important;
    border: 1px solid #0000001a !important;
    color: ${COLORS.black} !important;
    box-shadow: 0 3px 6px #00000080 !important;
    background-color: ${BACKGROUNDS.white} !important;
    box-sizing: border-box;
`

const Layout = () => {
    const history = useHistory()
    const location = useLocation()
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const [liveCasinoGamesCounter, setLiveCasinoGamesCounter] = useState({})
    const [gamesCategories, setGamesCategories] = useState([])
    const [offers, setOffers] = useState([])
    const [isGamePage, setIsGamePage] = useState(false)
    const [shouldShowScrollUp, setShouldShowScrollUp] = useState(false)
    const [shouldShowSubMenu, setShouldShowSubMenu] = useState(false)
    const [updateUserData, setUpdateUserData] = useState(false)
    const [scrollTopButtonPosition, setScrollTopButtonPosition] = useState(
        '70px'
    )
    const [minimizedGames, setMinimizedGames] = useState(null)
    const [title, setTitle] = useState(document.title)
    const [description, setDescription] = useState(
        document.querySelector('meta[name="description"]').content
    )
    const [providersData, setProvidersData] = useState([])

    const makeLiveCasinoCounter = (games) => {
        let counter = {}
        counter.all = games.all.length
        counter.roulette = games.roulette.length
        counter.blackjack = games.blackjack.length
        counter.baccarat = games.baccarat.length
        counter.poker = games.poker.length
        counter.gameShow = games.gameShow.length
        return counter
    }

    const beforeInstallPromptHandler = () => {
        window.deferredPrompt = event

        window.deferredPrompt.userChoice.then((choiceResult) => {
            const userId = localStorage.getItem('userId')
            if (choiceResult.outcome === 'accepted') {
                window.dataLayer.push({
                    event: 'casinogo.a2hs.accepted',
                    userId: userId || 0,
                })
            } else {
                window.dataLayer.push({
                    event: 'casinogo.a2hs.dismissed',
                    userId: userId || 0,
                })
            }
            window.deferredPrompt = null
        })
    }

    const appInstalledHandler = () => {
        const userId = localStorage.getItem('userId')
        window.dataLayer.push({
            event: 'casinogo.a2hs.installed',
            userId: userId || 0,
        })
    }

    useEffect(
        () => {
            ContentService.setSEOData(location.pathname)
        },
        [location.pathname]
    )

    useEffect(() => {
        function hasTouch() {
            return (
                'ontouchstart' in document.documentElement ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0
            )
        }

        if (!hasTouch()) document.body.className += ' hasHover'
        GamesService.fetchCombinedJackpots().then(() => {
            GamesService.fetchGames('new')

            GamesService.fetchLiveCasino()
            GamesService.fetchGames() // todo optimize fetchGames
            GamesService.fetchGamesCategories()
        })
        GamesService.fetchProvidersGames()
        ContentService.fetchCampaigns()
        ContentService.getAllHighlightsList()
        UserService.setIsUserLoggedIn()
        UserService.getClientId()
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                GamesService.fetchOffers()
            }) // todo CHECK HERE
        ContentService.fetchSEOData()
        initGEOData()
        const userLoggedStatusSub = UserService.getUserLoggedStatus().subscribe(
            (res) => {
                res ? GamesService.fetchFavorite() : null

                setIsUserLoggedIn(res)
            }
        )
        const liveCasinoSub = GamesService.getLiveCasinoGames().subscribe(
            (res) => {
                if (res && res.all && res.all.length) {
                    setLiveCasinoGamesCounter(makeLiveCasinoCounter(res))
                }
            }
        )

        const userInfoSub = UserService.getUserInfo().subscribe((data) => {
            setUserInfo(data)
            if (data && data.userId) {
                let getAvatar = localStorage.getItem('avatar')
                if (getAvatar) {
                    UserService.setUserAvatar(getAvatar)
                } else {
                    getAvatar = 1
                    UserService.setUserAvatar(getAvatar)
                }
            }
        })

        const offersSub = GamesService.getOffers().subscribe((res) => {
            setOffers(res)
        })
        const gamesCategoriesSub = GamesService.getGamesCategories().subscribe(
            (res) => {
                setGamesCategories(res)
            }
        )
        const providersSub = GamesService.getProvidersGames().subscribe(
            (res) => {
                if (res) {
                    setProvidersData(res)
                }
            }
        )
        const minimizedGameSub = GamesService.getMinimizedGame().subscribe(
            (games) => {
                if (games) {
                    setBackgroundPosition(games.fullScreen)
                }
                setMinimizedGames(games)
            }
        )

        const SEODataSub = ContentService.getSEOData().subscribe((res) => {
            if (res && Object.keys(res).length) {
                setTitle(res.title)
                setDescription(res.description)
            }
        })

        const transparencyCondition = !!PAGES_WITHOUT_MARGIN.find((a) =>
            location.pathname.includes(a)
        )
        setIsGamePage(transparencyCondition)

        window.document.addEventListener('scroll', (e) => {
            smoothscroll.polyfill()
            window.__forceSmoothScrollPolyfill__ = true
            let scrollTop =
                window.pageYOffset || document.documentElement.scrollTop
            scrollTop > 400
                ? setShouldShowScrollUp(true)
                : setShouldShowScrollUp(false)
            setShouldShowSubMenu(scrollTop > 10)
        })
        //Check if user logged in
        const interval = setInterval(() => {
            UserService.checkUserLogin().then((data) => {
                if (!data.login) {
                    clearInterval(interval)
                }
            })
        }, 30000)

        history.listen((location, action) => {
            const userId = localStorage.getItem('userId')
            window.dataLayer.push({
                event: 'casinogoPageView',
                eventUrl: location.pathname,
                userId: userId || 0,
            })
            window.scrollTo(0, 0)
            UrlHistoryService.setStack(location.pathname + location.search)
        })

        if (detectMobile()) {
            window.addEventListener(
                'popstate',
                function(event) {
                    setUpdateUserData(true)
                },
                false
            )
        }

        window.addEventListener(
            'beforeinstallprompt',
            beforeInstallPromptHandler
        )

        window.addEventListener('appinstalled', appInstalledHandler)

        if (location.pathname.indexOf('/mitid-verify/confirm') > -1) {
            const idp = location.pathname.split('/').reverse()[0] // todo rework ???
            setTimeout(
                () => ModalService.showModal('CPR_CONFIRMATION', { idp }),
                0
            )
        }

        return () => {
            userInfoSub.unsubscribe()
            gamesCategoriesSub.unsubscribe()
            offersSub.unsubscribe()
            userLoggedStatusSub.unsubscribe()
            liveCasinoSub.unsubscribe()
            minimizedGameSub.unsubscribe()
            providersSub.unsubscribe()
            SEODataSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            const verificationPopupShown = localStorage.getItem(
                'verificationPopupShown'
            )
            if (
                !verificationPopupShown &&
                userInfo &&
                userInfo.status === 'temp'
            ) {
                ModalService.showModal('VERIFICATION_POPUP', {}, false)
            }

            if (userInfo && userInfo.email) {
                UserAdapter.getDepositLimits().then((limit) => {
                    if (
                        limit.hasOwnProperty('dailyAmount') &&
                        limit.dailyAmount === 0 &&
                        limit.hasOwnProperty('weeklyAmount') &&
                        limit.weeklyAmount === 0 &&
                        limit.hasOwnProperty('monthlyAmount') &&
                        limit.monthlyAmount === 0 &&
                        limit.hasOwnProperty('dailyAmountChange') &&
                        limit.dailyAmountChange === 0 &&
                        limit.hasOwnProperty('weeklyAmountChange') &&
                        limit.weeklyAmountChange === 0 &&
                        limit.hasOwnProperty('monthlyAmountChange') &&
                        limit.monthlyAmountChange === 0
                    ) {
                        ModalService.showModal(
                            'FAST_DEPOSIT_LIMIT',
                            {
                                initialLimits: limit,
                                userStatus: userInfo.status,
                            },
                            false
                        )
                    }
                })
            }
        },
        [userInfo]
    )

    useEffect(
        () => {
            if (window.location.host === 'm.casinogo.dk') {
                window.location.href = window.location.href.replace(
                    '//m.',
                    '//www.'
                )
            }
        },
        [window.location.href]
    )

    const getScrollUpBottomPosition = () => {
        if (
            window.location.pathname.indexOf('live-casino') > -1 ||
            window.location.pathname.indexOf('alle-spil') > -1 ||
            window.location.pathname.indexOf('providers') > -1
        ) {
            setScrollTopButtonPosition(isUserLoggedIn ? '120px' : '190px')
        } else {
            setScrollTopButtonPosition(isUserLoggedIn ? '70px' : '130px')
        }
    }
    history.listen((location, action) => {
        const transparencyCondition = !!PAGES_WITHOUT_MARGIN.find((a) =>
            location.pathname.includes(a)
        )

        getScrollUpBottomPosition()
        setIsGamePage(transparencyCondition)
    })

    useEffect(
        () => {
            getScrollUpBottomPosition()
        },
        [window.location.pathname, isUserLoggedIn]
    )

    let setBackgroundPosition = (status) => {
        if (detectMobile()) {
            status
                ? (window.document.body.style.position = 'fixed')
                : (window.document.body.style.position = 'static')
        } else {
            status
                ? (window.document.body.style.overflow = 'hidden')
                : (window.document.body.style.overflow = 'auto')
        }
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const subMenuItems = SubMenuData(
        gamesCategories,
        liveCasinoGamesCounter,
        providersData
    )

    const checkUserDataLoading = userInfo && userInfo.loaded

    return (
        <Wrapper padding="0" height={'100vh'} width={'100%'}>
            <MetaTags>
                <title>{title}</title>
                <meta name="description" content={description} />
            </MetaTags>
            <Topbar />
            <Header
                offers={offers}
                liveCasinoGamesCounter={liveCasinoGamesCounter}
                userInfo={userInfo}
                isUserLoggedIn={isUserLoggedIn}
                transparent={isGamePage}
                withoutBalance={isGamePage}
                gamesCategories={gamesCategories}
                minimizedGames={minimizedGames}
                providersData={providersData}
            />

            {subMenuItems.some((el) =>
                location.pathname.includes(el.pathname)
            ) && (
                <SubMenu
                    show={shouldShowSubMenu}
                    items={
                        subMenuItems.find((item) =>
                            location.pathname.includes(item.pathname)
                        ).data
                    }
                />
            )}

            {minimizedGames &&
                minimizedGames.gamesPosition &&
                minimizedGames.games &&
                minimizedGames.games.length &&
                minimizedGames.games.map((game, index) => {
                    const {
                        minimizeMode,
                        multiScreenMode,
                        mode,
                        fullScreen,
                    } = minimizedGames
                    return (
                        <MinimizeScreen
                            key={'minimized-' + index}
                            game={game}
                            gamePosition={
                                minimizedGames.gamesPosition[game.game_id]
                            }
                            states={{
                                minimizeMode,
                                multiScreenMode,
                                mode,
                                fullScreen,
                            }}
                            isUserLoggedIn={isUserLoggedIn}
                            isGamePage={isGamePage}
                        />
                    )
                })}
            <PageWrapper
                offset={shouldShowSubMenu}
                top={
                    isGamePage
                        ? TOPBAR_DESKTOP_HEIGHT
                        : FULL_HEADER_SIZE_DESKTOP + 'px'
                }
            >
                {checkUserDataLoading && <DKRouter userProfile={userInfo} />}
                {!isUserLoggedIn && <Index />}
                <NavigationMenu
                    bottom
                    offers={offers}
                    liveCasinoGamesCounter={liveCasinoGamesCounter}
                    gamesCategories={gamesCategories}
                    providersData={providersData}
                />
                <Footer isUserLoggedIn={isUserLoggedIn} />
            </PageWrapper>
            <NotificationContainer />
            <Modals
                isModalVisible={(status) => setBackgroundPosition(status)}
            />
            <GEOBlock />
            {shouldShowScrollUp ? (
                <ScrollUp onClick={scrollTop} bottom={scrollTopButtonPosition}>
                    <Icon color={BACKGROUNDS.black} src={scrollIcon} />
                </ScrollUp>
            ) : null}
            <TooltipStyled id={'game-info'} arrowColor="transparent" />
            <LastLoginPopup />
        </Wrapper>
    )
}

export default Layout
