import Conductor from '../conductors/Conductor'
import PunterREST from 'features/PunterREST'
import CmsREST from 'features/CMSAPI'
import DepositLimitDTO from 'common/DataObjects/DepositLimitDTO'
import Payout from 'common/DataObjects/Payout'
import User from 'common/DataObjects/User'
import Profile from 'common/DataObjects/Profile'
import DepositBonus from 'common/DataObjects/Bonus'
import md5 from 'md5'
import { ModalService } from 'services/ModalService'
import { sha256 } from 'utils/formatters'
import * as FetchAdapter from 'common/adapters/FetchAdapter'
import { AUTH_REST_PATH } from 'configs/rest'

class UserAdapter extends Conductor {
    constructor() {
        const interfacesByLocales = {
            DK: {
                punter: PunterREST(),
                cms: CmsREST,
            },
        }

        super(interfacesByLocales)
    }

    /**
     * Hash user data for third-party company
     * @param user
     */

    addShaData(user) {
        sha256(user.firstName).then((res) => {
            localStorage.setItem('fn', res)
        })
        sha256(user.lastName).then((res) => {
            localStorage.setItem('ln', res)
        })
        sha256(user.email).then((res) => {
            localStorage.setItem('em', res)
        })
        sha256(user.phoneNr).then((res) => {
            localStorage.setItem('Pn', res)
        })
    }

    generateUseragentId() {
        const hash = md5(window.navigator.userAgent + 'bet25-web')

        return `web-application-${hash}`
    }

    register() {
        return this.handler.punter.register()
    }

    registerUserDeviceInCMS() {
        let uniqDeviceHash = this.generateUseragentId()

        try {
            this.handler.cms.registerDevice({
                hardware_id: uniqDeviceHash,
                useragent_id: uniqDeviceHash,
                mobileApp: 'casinogo',
            })
        } catch (e) {
            console.error('catch', e)
        }
    }

    attachUserToDevice(user) {
        let uniqDeviceHash = this.generateUseragentId()

        return this.handler.cms.attachUser({
            user_id: user.userId,
            fullname: `${user.firstName} ${user.lastName}`,
            email: user.email,
            balance: user.balance,
            username: user.userName,
            hardware_id: uniqDeviceHash,
            useragent_id: uniqDeviceHash,
            mobileApp: 'CasinoGo-web',
        })
    }

    setLastLoginDate(user) {
        let uniqDeviceHash = this.generateUseragentId()
        this.handler.cms.lastLogin({
            user_id: user.userId,
            mobileApp: 'CasinoGo-web',
            hardware_id: uniqDeviceHash,
            useragent_id: uniqDeviceHash,
        })
    }

    /**
     * Get current user data
     * @returns {Promise.<User>}
     */
    getUser() {
        return this.handler
            .getUser()
            .then((body) => User.unserialize(body.data))
    }

    /**
     * @param   {string} userName
     * @param   {string} password
     * @returns {Promise.<User>}
     */
    login(userName, password) {
        return this.handler.punter.login(userName, password).then((body) => {
            if (body.success) {
                let serializedUser = User.unserialize(body.data)

                return serializedUser
            } else {
                throw body
            }
        })
    }

    /**
     * @param   {string} result
     * @param   {string} content
     * @returns {Promise.<User>}
     */
    nemIdLogin(result, content) {
        return this.handler.punter.nemIdLogin(result, content).then((body) => {
            if (body.success) {
                return User.unserialize(body.data)
            } else {
                throw new Error(body.errorMessage)
            }
        })
    }

    /**
     * @returns {Promise.<User|false>}
     */
    isUserLoggedIn() {
        return this.handler.punter.isUserLoggedIn().then((body) => {
            if (
                this.user &&
                Object.keys(this.user).length &&
                !body.data &&
                !this.isLogout
            ) {
                setTimeout(
                    () => ModalService.showModal('SESSION_EXPIRED', {}, false),
                    1000
                )
            }
            this.isLogout = false
            this.user = body.data
            return body.success ? User.unserialize(body.data) : false
        })
    }

    logout() {
        this.isLogout = true
        return this.handler.punter.logout()
    }

    /**
     * @returns {Promise.<DepositLimitDTO>}
     */
    getDepositLimits() {
        return this.handler.punter.getDepositLimits().then((res) => {
            return DepositLimitDTO.unserialize(res.data)
        })
    }

    /**
     * @param   {DepositLimitDTO}           limit
     * @returns {Promise.<DepositLimitDTO>}
     */
    setDepositLimits(limit) {
        return this.handler.punter
            .setDepositLimits(limit.serialize())
            .then((res) => {
                return DepositLimitDTO.unserialize(res.data)
            })
    }

    /**
     * @return {Promise.<Array>.<DepositBonus>}
     */
    getDepositBonuses() {
        return this.handler.punter.getDepositBonuses().then((res) => {
            return res.data
                .map((bonus) => DepositBonus.unserialize(bonus))
                .filter((bonus) => bonus.isAvailable())
        })
    }

    getActiveBonusList() {
        return this.handler.punter.getActiveBonusList().then((res) => {
            return res.data
        })
    }

    /**
     * A payout is an object of containing state of users
     * status of withdrawal operations.
     * @returns {Promise.<Payout>}
     */
    getPayout() {
        let userTmp

        return this.getUser()
            .then((user) => {
                userTmp = user
                return userTmp
            })
            .then(this.handler.punter.getPayout)
            .then((body) => {
                const {
                    pendingBalance,
                    minOdds,
                    amount,
                    bonus,
                    rollover,
                } = body.data

                return new Payout(
                    userTmp,
                    pendingBalance,
                    minOdds,
                    amount,
                    bonus,
                    rollover
                )
            })
    }

    /**
     * Request withdraw / payout operation
     * @param   {number}          amount
     * @returns {Promise.<User>}
     */
    withdraw(amount) {
        return this.handler.punter.withdraw(amount)
    }

    /**
     * Set new pin login for the user
     * @param {number} pin
     */
    setPin(pin) {
        return this.handler.punter.setPin(pin).then((res) => res.data)
    }

    pinLogin(pin, pinHash, userName) {
        return this.handler.punter
            .pinLogin(pin, pinHash, userName)
            .then((res) => User.unserialize(res.data))
    }

    signupStep1(cpr) {
        return this.handler.punter.signup.step1(cpr)
    }

    signupStep2(result, signature) {
        return this.handler.punter.signup.step2(result, signature)
    }

    signupStep34(UserData) {
        return this.handler.punter.signup.step34(UserData)
    }

    expectedBetting(expectBetting) {
        return this.handler.punter.expectedBetting(expectBetting)
    }

    resetPassword(userName, password) {
        return this.handler.punter.resetPassword(userName, password)
    }

    getNemidParameters() {
        return this.handler.punter.getNemidParameters()
    }

    getSecurityQuestionList() {
        return this.handler.punter.getSecurityQuestionList()
    }

    getExpectedBetting() {
        return this.handler.punter.getExpectedBetting()
    }

    getUserStatus() {
        return this.handler.punter.getUserStatus()
    }

    getTransactionGroupList(group, dateFrom, dateTo, offset, rowCount) {
        return this.handler.punter.getTransactionGroupList(
            group,
            dateFrom,
            dateTo,
            offset,
            rowCount
        )
    }

    getTransactionList(dateFrom, dateTo) {
        return this.handler.punter.getTransactionList(dateFrom, dateTo)
    }

    getTransactionExternalList(type, dateFrom, dateTo) {
        return this.handler.punter.getTransactionExternalList(
            type,
            dateFrom,
            dateTo
        )
    }

    enableNewsletterSubscription() {
        return this.handler.punter.enableNewsletterSubscription()
    }

    disableNewsletterSubscription(status, email) {
        return this.handler.punter.disableNewsletterSubscription(status, email)
    }

    /**
     * @returns {Promise.<Profile>}
     */
    getProfile() {
        return this.handler.punter.getProfile().then((res) => {
            this.addShaData(res.data)
            return Profile.unserialize(res.data)
        })
    }

    /**
     * @param   {Profile}            profile
     * @returns {Promise.<Profile>}
     */
    updateProfile(profile) {
        return this.handler.punter.updateProfile(profile).then((res) => res)
    }

    /**
     *
     * @param  {string} currentPassword
     * @param  {string} newPassword
     * @param  {string} passwordConfirm
     * @return {Promise.<null>}
     */
    updatePassword(currentPassword, newPassword, passwordConfirm) {
        return this.handler.punter.updatePassword(
            currentPassword,
            newPassword,
            passwordConfirm
        )
    }

    /**
     *
     * @param  {integer} bonusCode
     * @param  {string} bonusNote
     * @return {Promise.<null>}
     */
    addTeam(bonusCode, bonusNote) {
        return this.handler.punter.addTeam(bonusCode, bonusNote)
    }

    applyVoucher(serial, code) {
        return this.handler.punter.depositVoucher(serial, code).then((res) => {
            if (res.success) {
                return res.data
            }
            throw res.errorMessage
        })
    }

    /**
     *
     * @param  {string} period
     * @return {Promise.<null>}
     */
    excludeSelf(period) {
        switch (period) {
            case 'hours':
                return this.handler.punter.excludeUserByPeriod('ex_24h')
            case 'month':
                return this.handler.punter.excludeUserByPeriod('ex_1m')
            case 'permanent':
                return this.handler.punter.excludeUserPermanently()
            default:
                return Promise.resolve(null)
        }
    }
}

export default new UserAdapter()
