import { NavLink } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes'
import { useHistory } from 'react-router-dom'
import Icon from 'ui/Icon'
import device from 'utils/mediaQueries'
import Text from 'ui/Text'
import boltICon from 'images/go/go-lightning.png'
import DropdownMenu from 'ui/Dropdown'
import arrowLeft from 'icons/left.svg'
import closeIcon from 'icons/close.svg'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import GameLabel from 'ui/GameLabel'
import ToolTip from 'ui/ToolTipUI'

const glowing = keyframes`
    0% {
        box-shadow: 0 0 8px 6px rgba(255, 255, 255, 0),
        0 0 0px 0px rgba(255, 255, 255, 0),
        0 0 0px 0px rgba(255, 255, 255, 0);
    }
    10% {
        box-shadow: 0 0 5px ${BACKGROUNDS.primary},
        0 0 10px ${BACKGROUNDS.primary},
        0 0 15px ${BACKGROUNDS.primary};
    }
    100% {
        box-shadow: 0 0 8px 6px rgba(26, 255, 255, 0),
        0 0 0px 40px rgba(26, 255, 255, 0),
        0 0 0px 60px rgba(26, 255, 255, 0);
    }
`
const rotate = keyframes` 100% {
                              transform: rotate(360deg);
                          } `

const wrapperCss = css`
    font-size: 12px;
    border: none;
    width: 90px;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    transition: all 0.5s ease;
    color: ${COLORS.white};
    text-decoration: none;
    z-index: 3;

    body.hasHover &:hover {
        ${Icon} {
            background-color: ${BACKGROUNDS.primary};
        }
    }

    &:after {
        transition: all 0.5s ease;
        content: ${({ active }) => (active ? active : '')};
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: ${BACKGROUNDS.primary};
    }
`
const StyledLink = styled(NavLink)`
    ${wrapperCss};

    & a {
        position: relative;
        color: ${COLORS.white};
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
    }
`
const StyledNavItem = styled.div`
    ${wrapperCss};
    cursor: pointer;
`
const NavIconWrapper = styled.div`
    padding: 10px 25px;
    border-radius: 5px;
    transition: all 0.2s ease;

    body.hasHover &:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }

    @media screen and ${device.sm} {
        padding: 10px 15px;
        background: ${({ dropdownOpened }) =>
            dropdownOpened && BACKGROUNDS.grey200};
    }
`
const GoIconWrapper = styled.div`
    color: ${COLORS.white};
    padding: 10px 25px;
    border-radius: 5px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: relative;
`
const NavIcon = styled(Icon)`
    background-color: ${BACKGROUNDS.white};
    width: 25px;
    height: 25px;
    margin-bottom: 3px;
    transition: all 0.2s ease;
    @media screen and ${device.sm} {
        width: ${({ dropdownOpened }) => (dropdownOpened ? '20px' : '25px')};
    }
`
const GoIcon = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: ${BACKGROUNDS.primary};
    margin-bottom: 3px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    position: relative;
    font-family: 'RoadRage', sans-serif;
    animation: ${({ activeCampaign }) => (activeCampaign ? glowing : '')} 2s
        infinite;

    body.hasHover &:hover {
        box-shadow: 0 3px 30px ${BACKGROUNDS.primary};
    }
`
const BoltImage = styled.img`
    position: absolute;
    width: 100px;
    height: 100px;
    left: 0;
    top: -15px;
    animation: ${rotate} 1s linear infinite;
`

const DropdownItem = styled.div`
    padding: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease;
    border-radius: 5px;

    ${Icon} {
        background-color: ${BACKGROUNDS.grey} !important;
    }

    @media screen and ${device.xs} {
        padding: 4px;
    }

    .hasHover &:hover {
        background-color: ${BACKGROUNDS.grey300};
        filter: brightness(120%);
    }
`
const BackButton = styled.div`
    background: ${BACKGROUNDS.grey300};
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: 70px;
    transition: all 0.3s ease;

    ${Icon} {
        background-color: ${BACKGROUNDS.grey} !important;
    }

    ${Text}:last-child {
        margin: 0;
        color: white;
    }

    .hasHover &:hover {
        filter: brightness(150%);
    }
`
const LabelWrapper = styled.span`
    display: block;
    position: relative;
    left: 10px;
`

const MenuItem = (props) => {
    const history = useHistory()
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const [clickOutside, setClickOutside] = useState(false)
    const [newDropdown, setNewDropdown] = useState(props.dropdown)

    const toggleDropdown = (e, path = null) => {
        e.preventDefault()
        e.stopPropagation()
        if (clickOutside && !dropdownOpened) {
            setDropdownOpened(true)
        } else {
            setDropdownOpened(false)
        }
        if (path) {
            history.push(path)
        }
    }

    useEffect(
        () => {
            setNewDropdown(props.dropdown)
        },
        [props.dropdown]
    )

    useEffect(
        () => {
            if (!clickOutside && dropdownOpened) {
                setDropdownOpened(false)
            }
        },
        [clickOutside]
    )

    useEffect(
        () => {
            if (dropdownOpened && props.dropdown) {
                setNewDropdown(props.dropdown)
            }
        },
        [dropdownOpened]
    )

    const dropdownElements = (el) => {
        switch (el.type) {
            case 'providers':
                return (
                    <DropdownItem
                        key={el.name}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setNewDropdown(el.providers)
                        }}
                    >
                        <Text>{el.name}</Text>
                        <Text>{el.icon}</Text>
                    </DropdownItem>
                )
            case 'backButton':
                return (
                    <BackButton
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setNewDropdown(props.dropdown)
                        }}
                        key={`back-button-${el.name}`}
                    >
                        <Icon
                            src={arrowLeft}
                            alt={'arrowLeft'}
                            color={BACKGROUNDS.grey}
                            width="12px"
                            height="12px"
                        />
                        <Text>{el.name}</Text>
                    </BackButton>
                )
            default:
                return (
                    <DropdownItem
                        key={el.path}
                        onClick={(e) => {
                            toggleDropdown(e, el.path)
                        }}
                    >
                        <Text
                            style={
                                el.newLabel && {
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxHeight: '21px',
                                }
                            }
                        >
                            {el.name}
                            {el.newLabel && (
                                <LabelWrapper>
                                    <GameLabel
                                        {...{
                                            background: '#F0284A',
                                            textColor: '#fff',
                                            text: 'NYT',
                                        }}
                                    />
                                </LabelWrapper>
                            )}
                        </Text>
                        <Text>{el.count}</Text>
                    </DropdownItem>
                )
        }
    }

    const dropdownItem = (
        <StyledNavItem
            to={props.path}
            active={props.active}
            onClick={toggleDropdown}
        >
            <NavIconWrapper path={props.path} dropdownOpened={dropdownOpened}>
                <NavIcon
                    dropdownOpened={dropdownOpened}
                    src={
                        dropdownOpened && detectMobile()
                            ? closeIcon
                            : !props.active || !props.iconActive
                                ? props.icon
                                : props.iconActive
                    }
                />
                <DropdownMenu
                    open={dropdownOpened}
                    setDropdownOpened={() => setClickOutside(!dropdownOpened)}
                    style={{
                        maxHeight: 442,
                        overflowY: 'auto',
                        left: !detectMobile() ? '-100px' : 0,
                    }}
                >
                    {newDropdown &&
                        newDropdown.map((el) => dropdownElements(el))}
                </DropdownMenu>
                <ToolTip parent={NavIconWrapper}>{props.title}</ToolTip>
            </NavIconWrapper>
        </StyledNavItem>
    )

    const linkItem =
        props.path !== '/go' ? (
            <StyledLink to={props.path} active={props.active}>
                <NavIconWrapper path={props.path}>
                    <NavIcon
                        src={
                            !props.active || !props.iconActive
                                ? props.icon
                                : props.iconActive
                        }
                    />
                    <ToolTip parent={StyledLink}>{props.title}</ToolTip>
                </NavIconWrapper>
            </StyledLink>
        ) : (
            <GoIconWrapper path={props.path} onClick={props.onClick}>
                <GoIcon
                    activeCampaign={props.activeCampaign}
                    onClick={props.onClick}
                    goIcon={true}
                    src={
                        !props.active || !props.iconActive
                            ? props.icon
                            : props.iconActive
                    }
                >
                    GO
                </GoIcon>
                <ToolTip parent={GoIconWrapper}>{props.title}</ToolTip>
                {props.activeCampaign ? <BoltImage src={boltICon} /> : null}
            </GoIconWrapper>
        )

    return newDropdown ? dropdownItem : linkItem
}

export default MenuItem
