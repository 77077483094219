import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import getTexts from 'utils/localization'
import { ModalService } from 'services/ModalService'
import { Withdraw as WithdrawComponent } from '@it25syv/25syv-ui'
import UserAdapter from 'common/adapters/UserAdapter'
import userService from 'services/UserService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import BonusService from 'services/BonusService'
import { useHistory } from 'react-router'

const t = getTexts()

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const Withdraw = ({ close, actions }) => {
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    const [bonuses, setBonuses] = useState([])

    const [userData, setUserData] = useState({})
    const [relatedGameId, setRelatedGameId] = useState('')

    const transferMoneyHandler = (amount) => {
        setLoading(true)
        UserAdapter.withdraw(amount)
            .then((res) => {
                if (res.success) {
                    userService.setIsUserLoggedIn()
                    ModalService.showModal('WITHDRAW_NOTIFICATION_POPUP', {
                        amount,
                    })
                } else {
                    NotificationConductor.error('Error: ' + res.errorMessage)
                }
                setLoading(false)
            })
            .catch((error) => {
                NotificationConductor.error(error.errorMessage)
                setLoading(false)
            })
    }

    const getRedirectUrl = () => {
        ModalService.closeModal()

        if (relatedGameId) {
            history.push('/spil/' + relatedGameId + '/money/')
        } else {
            history.push('/alle-spil/slots')
        }
    }

    useEffect(() => {
        BonusService.setActiveBonusList()
        userService.checkUserLogin()
        const userSubscription = userService
            .getUserProfile()
            .subscribe((data) => {
                setLoading(false)
                setUserData(data)
            })

        const activeBonusSubscription = BonusService.getActiveBonusList().subscribe(
            (data) => {
                setBonuses(data)

                if (data.length) {
                    data.find((bonusGame) => {
                        if (bonusGame.bonusType === 'W') {
                            setRelatedGameId(bonusGame.gameId)
                        }
                    })
                }
            }
        )

        return () => {
            userSubscription.unsubscribe()
            activeBonusSubscription.unsubscribe()
            userService.setIsUserLoggedIn()
        }
    }, [])

    return (
        <Wrapper>
            <ModalTopBar
                modalName="WITHDRAW"
                title={t.userArea.withdraw.title}
            />
            <WithdrawComponent
                product={'casinogo'}
                currentTheme={'dark'}
                userData={userData}
                bonusesData={bonuses}
                onRemove={(bonusAmount) => {
                    ModalService.showModal('REMOVE_BONUS_CONFIRM', {
                        bonusAmount,
                    })
                }}
                transferMoneyHandler={(amount) => {
                    transferMoneyHandler(amount)
                }}
                redirectUrl={getRedirectUrl}
                payoutPending={loading}
            />
        </Wrapper>
    )
}

export default Withdraw
