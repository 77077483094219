import React, { Fragment, useEffect, useState } from 'react'
import ModalTopBar from 'ui/ModalTopBar'
import Wrapper from 'ui/Wrapper'
import Button from 'ui/controls/Button'
import Text from 'ui/Text'
import Flex from 'ui/Flex'
import styled from 'styled-components'
import ValidInput from 'ui/controls/ValidInput'
import NotificationConductor from 'common/conductors/NotificationConductor'
import UserAdapter from 'common/adapters/UserAdapter'
import Icon from 'ui/Icon'
import edit from 'images/icons/pen.svg'
import withValidation from 'features/Hocs/WithValidation'
import { ModalService } from 'services/ModalService'
import Avatar from 'features/SignUp/Avatar'
import { Headline5 } from 'ui/Headline'
import { isFunction } from 'utils/object-helper'
import UserService from 'services/UserService'
import getTexts from 'utils/localization'
import { userAreaBackUrl } from '@/routers/helper'
import { ValidatingInput } from '@it25syv/25syv-ui'

const t = getTexts()

const StyledInput = styled(Flex)`
    margin: 0;
`

const YourInfo = ({
    setValidationHandler,
    validateAllFields,
    theme,
    close,
    data: generalInfo = {},
    ...props
}) => {
    const [userProfile, setUserProfile] = useState({
        firstName: '',
        lastName: '',
    })
    const [userInfo, setUserInfo] = useState({})

    const [updateButtonDisable, setUpdateButtonDisable] = useState(true)

    const [email, setEmail] = useState(generalInfo.email || '')
    const [address, setAddress] = useState(generalInfo.address || '')
    const [zip, setZip] = useState(generalInfo.zip || '')
    const [phoneNr, setMobile] = useState(generalInfo.phoneNr || '')
    const [city, setCity] = useState(generalInfo.city || '')
    const [lastLogin, setLastLogin] = useState(generalInfo.lastLogin || '')

    const closeModal = (modalName = '') => {
        isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }

    const submitHandler = (e) => {
        e.preventDefault()

        if (validateAllFields()) {
            UserAdapter.updateProfile({
                email,
                address,
                zip,
                phoneNr,
                city,
            })
                .then((res) => {
                    UserService.setIsUserLoggedIn()
                    UserService.setUserProfile()
                    res.success
                        ? NotificationConductor.success(
                              'Dine kontooplysninger er blevet ændret'
                          )
                        : NotificationConductor.error(res.errorMessage)
                })
                .catch((err) => {
                    NotificationConductor.error(err.errorMessage)
                })
        } else {
            NotificationConductor.error(t.filledWrong)
        }
    }

    const backHandler = () => {
        closeModal('YOUR_INFO')
        window.history.pushState('', '', userAreaBackUrl('settings'))
    }

    const onKeyPress = (e) => {
        const key = e.charCode || e.keyCode || 0
        if (key === 13) {
            submitHandler(e)
        }
    }

    useEffect(() => {
        const subscription = UserService.getUserProfile().subscribe((res) => {
            setUserInfo(res)
            setEmail(res.email)
            setLastLogin(res.lastLogin)
            setUserProfile(res)
            setAddress(res.address)
            setZip(res.zip)
            setMobile(res.phoneNr)
            setCity(res.city)
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <Fragment>
            <ModalTopBar title="Kontooplysninger" modalName="YOUR_INFO" />
            {userProfile ? (
                <Wrapper
                    style={{ textAlign: 'center' }}
                    onKeyPress={(e) => {
                        onKeyPress(e)
                    }}
                >
                    <Avatar />
                    {(generalInfo.firstName || userProfile.firstName) &&
                    (generalInfo.lastName || userProfile.lastName) ? (
                        <Headline5 padding="0" align={'center'} bold margin="0">
                            {`${generalInfo.firstName ||
                                userProfile.firstName} ${generalInfo.lastName ||
                                userProfile.lastName}`}
                        </Headline5>
                    ) : null}

                    {userProfile && userProfile.userName ? (
                        <Text size={'x2'} align={'center'} margin={'0 0 70px'}>
                            {userProfile.userName}
                        </Text>
                    ) : null}

                    <StyledInput>
                        <ValidInput
                            onChange={(v) => {
                                setEmail(v)
                                setUpdateButtonDisable(v === userInfo.email)
                            }}
                            value={email}
                            validate={{
                                required: true,
                                email: true,
                            }}
                            placeholder={t.signupWindow.step4.email}
                            getValidator={(validatorHandler) =>
                                setValidationHandler('email', validatorHandler)
                            }
                            rightText={<Icon src={edit} size={'sm'} />}
                            hideCancel
                        />
                    </StyledInput>
                    <StyledInput>
                        <ValidInput
                            onChange={(v) => {
                                setMobile(v)
                                setUpdateButtonDisable(
                                    v === userProfile.phoneNr
                                )
                            }}
                            value={phoneNr}
                            placeholder={t.signupWindow.step4.mobile}
                            validate={{
                                required: true,
                                numeric: true,
                                minLength: 8,
                            }}
                            getValidator={(validatorHandler) =>
                                setValidationHandler(
                                    'mobileNumber',
                                    validatorHandler
                                )
                            }
                            rightText={<Icon src={edit} size={'sm'} />}
                            hideCancel
                        />
                    </StyledInput>
                    <StyledInput>
                        <ValidInput
                            onChange={(v) => {
                                setAddress(v)
                                setUpdateButtonDisable(
                                    v === userProfile.address
                                )
                            }}
                            value={address}
                            placeholder={t.user.address}
                            getValidator={(validatorHandler) =>
                                setValidationHandler(
                                    'address',
                                    validatorHandler
                                )
                            }
                            hideCancel
                            disabled
                        />
                    </StyledInput>
                    <StyledInput>
                        <ValidInput
                            onChange={(v) => {
                                setZip(v)
                                setUpdateButtonDisable(v === userProfile.zip)
                            }}
                            value={zip}
                            placeholder={t.user.zip}
                            getValidator={(validatorHandler) =>
                                setValidationHandler('zip', validatorHandler)
                            }
                            hideCancel
                            disabled
                        />
                    </StyledInput>
                    <StyledInput>
                        <ValidInput
                            onChange={(v) => {
                                setCity(v)
                                setUpdateButtonDisable(v === userProfile.city)
                            }}
                            value={city}
                            placeholder={t.user.city}
                            getValidator={(validatorHandler) =>
                                setValidationHandler('city', validatorHandler)
                            }
                            hideCancel
                            disabled
                        />
                    </StyledInput>
                    <Text align="center">
                        Sidste log ind: <br /> {lastLogin}
                    </Text>

                    {!updateButtonDisable && (
                        <Flex justify={'space-between'} margin="20px 0 0">
                            <Button
                                accent
                                size={'lgB'}
                                width={'100%'}
                                onClick={submitHandler}
                                disabled={updateButtonDisable}
                            >
                                Bekræft
                            </Button>
                            <Button
                                black
                                size={'lgB'}
                                width={'100%'}
                                onClick={backHandler}
                            >
                                Annuller
                            </Button>
                        </Flex>
                    )}
                </Wrapper>
            ) : null}
        </Fragment>
    )
}

export default withValidation(YourInfo)
