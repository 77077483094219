import { BehaviorSubject, Subject } from 'rxjs'
import qs from 'qs'
import axios from 'axios'
import {
    API_PHP,
    CAMPAIGNS_DEV_PATH,
    CAMPAIGNS_PATH,
    CASINOGO_REST_API,
    MAIN_REST_BASE_API,
} from 'configs/rest'
import { get } from 'common/adapters/FetchAdapter'
import { DOMAIN_PATH } from 'configs/main'
import CmsREST from 'features/CMSAPI'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'

const r = '?r='

const Texts = new BehaviorSubject({})
const Campaigns = new BehaviorSubject([])
const ViewedCampaigns = new BehaviorSubject([])
const Highlights = new BehaviorSubject([])
const SEOData = new BehaviorSubject([])
const SEODataPage = new BehaviorSubject({})

const request = (requestType, url, data) => {
    const options = {
        method: requestType,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
    }

    if (requestType === 'get') {
        options.params = data
    }

    return axios(options).then((res) => {
        return res.data
    })
}

const getPromoPageData = (
    urlParam = window.location.pathname.split('/').slice(-1)
) => {
    const hostName = 'casinogo_'
    return get(
        `${DOMAIN_PATH}/rest/landing-page/get-page?url=${urlParam}&hostName=${hostName}`
    )
}

const fetchSEOData = () => {
    return CmsREST.getSEOFromAPI({
        site: 'casinogo',
    })
        .then((response) => {
            SEOData.next(response.data)
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
        })
}

export default {
    getAllTextsList: () => {
        request('get', MAIN_REST_BASE_API + API_PHP + r + 'Texts').then(
            (res) => {
                Texts.next(res)
            }
        )
    },
    fetchCampaigns: () => {
        get(`${CASINOGO_REST_API}${CAMPAIGNS_PATH}`).then((res) => {
            if (res.data) {
                Campaigns.next(res.data)
            }
        })
    },

    getGoTexts: () => {
        return get(`${MAIN_REST_BASE_API}api.php`, {
            r: 'Texts',
            group: 'inactive-button',
        })
    },

    getCampaigns: Campaigns.asObservable(),
    getAllTexts: Texts.asObservable(),
    getAllHighlightsList: () => {
        request('get', MAIN_REST_BASE_API + API_PHP + r + 'Highlights').then(
            (res) => {
                Highlights.next(res)
            }
        )
    },
    getAllHighlights: Highlights.asObservable(),
    getPromoPageData,
    fetchSEOData,
    setSEOData: (url = '/') => {
        const res = SEOData.value

        if (res.length) {
            const pageData =
                res.find((item) => item.url === url) ||
                res.find((item) => item.url === '/')
            SEODataPage.next(pageData || null)
        }
    },
    getSEOData: () => SEODataPage.asObservable(),
}
