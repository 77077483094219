import React, { Fragment } from 'react'
import {
    CollapseControlIcon,
    ControlFrame,
    ControlsGroup,
    ControlWrapper,
    DragWrapper,
    ResizeIcon,
    GameNameWrapper,
    GameName,
    MoveWrapper,
    MoveIcon,
} from 'features/MinimizeScreen/styled'
import { ControlIcon } from 'features/GameArea/styled'
import closeIcon from 'icons/close.svg'
import maxiScreenIcon from 'icons/maxiscreen.svg'
import resizeWindowIcon from 'icons/resize-window.svg'
import allDirectionsIcon from 'icons/all-directions.svg'
import leftIcon from 'icons/left.svg'
import PropTypes from 'prop-types'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router'

const MiniControls = ({ gameName, gameId, mode, collapseGame, collapse }) => {
    const history = useHistory()

    const closeGame = () => {
        GamesService.getEndGame()
        GamesService.cleanMinimizedData()
    }

    const maximizeGame = () => {
        history.push(`/spil/${gameId}/${mode}`)
    }

    return (
        <ControlWrapper collapsed={collapse}>
            <ControlFrame collapsed={collapse} onClick={collapseGame}>
                <CollapseControlIcon
                    width="18px"
                    height="18px"
                    src={leftIcon}
                    collapsed={collapse}
                />
            </ControlFrame>
            <DragWrapper className={'handle'} collapsed={collapse}>
                {!collapse && (
                    <Fragment>
                        <GameNameWrapper>
                            <GameName>{gameName}</GameName>
                        </GameNameWrapper>
                    </Fragment>
                )}
            </DragWrapper>
            {!collapse && (
                <MoveWrapper>
                    <MoveIcon
                        width="115px"
                        height="115px"
                        src={allDirectionsIcon}
                    />
                    <ResizeIcon
                        width="35px"
                        height="35px"
                        src={resizeWindowIcon}
                    />
                </MoveWrapper>
            )}
            <ControlsGroup collapsed={collapse}>
                <ControlFrame collapsed={collapse} onClick={maximizeGame}>
                    <ControlIcon
                        width="18px"
                        height="18px"
                        src={maxiScreenIcon}
                    />
                </ControlFrame>
                <ControlFrame
                    gap={collapse}
                    collapsed={collapse}
                    onClick={closeGame}
                >
                    <ControlIcon width="18px" height="18px" src={closeIcon} />
                </ControlFrame>
            </ControlsGroup>
        </ControlWrapper>
    )
}

MiniControls.propsTypes = {
    gameId: PropTypes.string,
    mode: PropTypes.string,
    collapseGame: PropTypes.func,
    collapse: PropTypes.bool,
}

export default MiniControls
