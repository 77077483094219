import React from 'react'
import styled from 'styled-components'
import Button from 'ui/controls/Button'
import { ModalService } from 'services/ModalService'
import { detectIpad, detectMobile } from 'common/adapters/DeviceDetectAdapter'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import closeButton from 'icons/close_white.png'
import Icon from 'ui/Icon'
import { BACKGROUNDS, COLORS } from 'themes'
import { BREAKPOINTS } from 'themes/dk/breakpoints'
import getTexts from 'utils/localization'

const t = getTexts()

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    width: 80%;
    background: ${BACKGROUNDS.white};
    color: ${BACKGROUNDS.primary};
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    box-sizing: border-box;
`

const ClosePopup = styled.div`
    position: absolute;
    cursor: pointer;
    top: 15px;
    right: 15px;
    background: #c1c1c1;
    border-radius: 5px;
    height: 27px;
    width: 27px;
    transition: all 0.3s ease;
    &:hover {
        background: #585858;
    }

    img,
    svg {
        height: 10px;
        width: 10px;
        line-height: 25px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`

const Title = styled.h3`
    font-size: 24px;
    margin: 0;
    color: ${COLORS.black};
`

const Description = styled.p`
    flex: 1 1 auto;
    width: auto;
    font-size: 14px;
    padding: 10px 0;
    color: ${COLORS.black};
    text-align: left;
    font-weight: normal;
    text-decoration: none;
    margin: 19px 0;
`

const ButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const StyledButton = styled(Button)`
    width: 100%;
    max-width: 48%;
    margin: 0;

    @media (max-width: ${BREAKPOINTS.sm + 'px'}) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

const EmailAlreadyExist = (modalData) => {
    const onLoginClick = () => {
        ModalService.closeModal()
        setTimeout(() => {
            ModalService.showModal('LOGIN')
        }, 0)
    }

    return (
        <Wrapper closeBtn>
            <Title>Velkommen tilbage!</Title>
            <ClosePopup
                onClick={() => {
                    ModalService.closeModal('EMAIL_EXIST')
                }}
            >
                <Icon size={'sm'} src={closeButton} color={'white'} />
            </ClosePopup>
            <Description>
                {t.signupWindow.modalsExists[modalData.data.message]}
            </Description>

            <ButtonsArea>
                <StyledButton
                    black
                    size="x2"
                    onClick={() => {
                        ModalService.closeModal()
                        IntercomAdapter.show()
                    }}
                >
                    Kontakt os
                </StyledButton>
                <StyledButton accent size="x2" onClick={onLoginClick}>
                    Log ind
                </StyledButton>
            </ButtonsArea>
        </Wrapper>
    )
}

export default EmailAlreadyExist
