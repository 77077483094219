import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import Icon from 'ui/Icon'
import Text from 'ui/Text'
import device from 'utils/mediaQueries'
import { BACKGROUNDS } from 'themes/index'
import { useHistory } from 'react-router-dom'
import { providersData } from 'configs/ui'

const PlaymatesWrapper = styled.div`
    max-width: 1280px;
    margin: 100px auto;
    padding: 0 20px;
    box-sizing: border-box;

    @media screen and ${device.xl} {
        max-width: 979px;
        margin-bottom: 40px;
    }

    @media screen and ${device.md} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media screen and ${device.sm} {
        ${HeadingWithBadge} {
            font-size: 24px;
        }

        padding: 0;
    }
`
const PlaymatesItems = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-column-gap: 10px;
    width: 100%;
    @media screen and ${device.md} {
        grid-template-columns: repeat(3, 32%);
        grid-column-gap: 5px;
    }
`
const PlaymatesItem = styled.div`
    width: 100%;
    height: 157px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding: 20px;
    box-sizing: border-box;

    &:not(.playmates-more) {
        background: ${BACKGROUNDS.secondary};
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);

        .hasHover &:hover {
            transform: scale(1.02);
            box-shadow: 0 0 20px 5px rgb(255 255 255 / 10%);
        }
    }

    &.upcoming-playmates {
        filter: blur(5px);
    }

    @media screen and ${device.md} {
        height: 20vw;
        min-height: 78px;
    }
    @media screen and ${device.sm} {
        ${Text} {
            font-size: 12px;
        }

        ${Icon} {
            width: 74px;
        }
    }
`

const Playmates = ({ title, providers = [] }) => {
    const history = useHistory()
    const [filteredData, setFilteredData] = useState([])

    useEffect(
        () => {
            if (providers.length && !filteredData.length) {
                setFilteredData(
                    providers.reduce((acc, { name }) => {
                        const data = providersData.find((p) => p.name === name)
                        if (data) acc.push(data)
                        return acc
                    }, [])
                )
            }
        },
        [providers]
    )

    return (
        <PlaymatesWrapper>
            <HeadingWithBadge size="x5" bold>
                {title}
            </HeadingWithBadge>
            <PlaymatesItems>
                {filteredData.length &&
                    filteredData.map((provider, key) => (
                        <PlaymatesItem
                            className={!provider.name && 'upcoming-playmates'}
                            key={key}
                            onClick={() =>
                                provider.name &&
                                history.push(`providers/${provider.name}`)
                            }
                        >
                            <Icon
                                width={provider.width || '110px'}
                                height="100%"
                                src={provider.logo}
                            />
                        </PlaymatesItem>
                    ))}
                {/*<PlaymatesItem className="playmates-more">*/}
                {/*    <Text align="center" size="x2">*/}
                {/*        flere på vej…*/}
                {/*    </Text>*/}
                {/*</PlaymatesItem>*/}
            </PlaymatesItems>
        </PlaymatesWrapper>
    )
}

Playmates.propTypes = {
    title: propTypes.string.isRequired,
    providersData: propTypes.array.isRequired,
}
export default Playmates
