import { GAME_LAUNCH_API, CASINOGO_REST_API } from 'configs/rest'
import { BehaviorSubject, combineLatest } from 'rxjs'
import {
    detectIpad,
    detectMobile,
    getPlatformForApi,
} from 'common/adapters/DeviceDetectAdapter'
import { get, post } from 'common/adapters/FetchAdapter'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { startGame } from 'services/GameLaunchService'
import { ModalService } from 'services/ModalService'
import axios from 'axios'
import getTexts from 'utils/localization'
import { getEnv, getEnvWithBeta } from 'utils/env'
import UserService from 'services/UserService'
import { setCookie } from 'services/CookieService'
import { APP_NAME, MAX_MULTI_GAMES_COUNT } from 'configs/main'
import { RECENTLY_PLAYED_GAMES_AMOUNT } from 'configs/layout'
import CmsREST from 'features/CMSAPI'
import { sortingByHotClicks } from 'utils/sorting'
import { providersData } from 'configs/ui'
import { formatDenmarkCurrent } from 'utils/ui'

const t = getTexts()
const allGames = new BehaviorSubject([])
const categorizedGames = new BehaviorSubject([])
const liveCasinoGames = new BehaviorSubject({})
const gamesConfig = new BehaviorSubject(null)
const multipleGames = new BehaviorSubject([])
const newGames = new BehaviorSubject([])
const hiddenGames = new BehaviorSubject([])
const bonusGames = new BehaviorSubject([])
const jackpotGames = new BehaviorSubject([])
const recentlyPlayedGames = new BehaviorSubject([])
const isGameLaunched = new BehaviorSubject(false)
const favoriteGames = new BehaviorSubject([])
const offers = new BehaviorSubject([])
const providersGames = new BehaviorSubject(null)
const GamesStatus = new BehaviorSubject({
    pending: false,
    success: false,
    error: '',
})
const gamesCategories = new BehaviorSubject([])
const minimizedGamesData = new BehaviorSubject({})
const endGameUrl = new BehaviorSubject('')

const mapLiveGames = (games) => {
    let liveCasino = {
        all: [...games],
        baccarat: [],
        roulette: [],
        blackjack: [],
        poker: [],
        gameShow: [],
        hot: [],
    }
    games.forEach((game) => {
        if (game.subcat === 'baccarat') {
            liveCasino.baccarat.push(game)
        } else if (game.subcat === 'roulette') {
            liveCasino.roulette.push(game)
        } else if (game.subcat === 'blackjack') {
            liveCasino.blackjack.push(game)
        } else if (game.subcat === 'poker') {
            liveCasino.poker.push(game)
        } else if (game.subcat === 'andet') {
            //'gameShow' in cms === 'andet'
            liveCasino.gameShow.push(game)
        }
        if (game.go_hot) {
            liveCasino.hot.push(game)
        }
    })
    return liveCasino
}
const fetchGames = (
    endpoint,
    data = {
        platform: getPlatformForApi(),
        srv: getEnv(),
    }
) => {
    if (!data.platform) {
        data.platform = getPlatformForApi()
        data.srv = getEnv()
    }
    return get(CASINOGO_REST_API + 'games' + endpoint, data)
}
const gameLike = (game_id) => {
    const user_id = localStorage.getItem('userId')
    if (!user_id) {
        return Promise.reject(new Error(t.LOGINFIRST))
    }
    return axios.post(`${CASINOGO_REST_API}clients/${user_id}/games/${game_id}`)
}
const gameUnLike = (game_id) => {
    const user_id = localStorage.getItem('userId')
    if (!user_id) {
        return Promise.reject(new Error(t.LOGINFIRST))
    }
    return axios.delete(
        `${CASINOGO_REST_API}clients/${user_id}/games/${game_id}`
    )
}
const getLaunchLink = (options) => {
    let requestParams = {
        ...options,
        platform: getPlatformForApi(),
        lobby:
            detectMobile() && !detectIpad()
                ? window.location.origin
                : window.location.origin + '/lobby',
    }

    if (!detectMobile() || detectIpad()) {
        requestParams.platform = 'web'
    }

    return post(GAME_LAUNCH_API + '/games/launch/casinogo', requestParams)
        .then((res) => {
            if (res.error) {
                console.log(res.error)
                NotificationConductor.error(
                    'Din session er udløbet, log venligst ind igen.'
                )
            }

            if (res.data) {
                let link = res.data.url
                if (res.data.meta && res.data.meta.endgame) {
                    endGameUrl.next(res.data.meta.endgame)
                }
                if (res.data.params && res.data.params['set-cookie']) {
                    let netentCookie =
                        res.data.params['set-cookie']
                            .replace('NetEnt=', '')
                            .replace('; path=/; Secure', '') +
                        ';.domain=casinogo-static.casinomodule.com'

                    setCookie('NetEnt', netentCookie)
                }
                return link
            }
        })
        .catch((error) => {
            console.log(error)
            NotificationConductor.error(
                'Din session er udløbet, log venligst ind igen.'
            )
        })
}
const getGameLaunchUrl = (options) => {
    if (options.mode === 'fun') {
        return getLaunchLink({
            ...options,
        })
    }

    return post('/getSes.php')
        .then((data) => {
            return getLaunchLink({
                ...options,
                session_id: 'JSESSIONID=' + data.sessId,
            })
        })
        .catch((err) => {
            console.log(err)
            NotificationConductor.error(
                'Din session er udløbet, log venligst ind igen.'
            )
        })
}
const checkLoginBeforeStart = (game, history, mode, logged) => {
    UserService.checkUserLogin().then(({ login }) => {
        const showSessionPopup = !login && logged
        if (!showSessionPopup) {
            LaunchGame(game, history, mode)
        }
    })
}
const LaunchGame = (game, history, mode = 'fun') => {
    if (game.subprovider === 'evolution') {
        startGame(
            game,
            (link) => {
                window.location.href = link
            },
            mode
        )
        return
    }

    if (game.game_id) {
        if (detectMobile() && !detectIpad()) {
            startGame(
                game,
                (link) => {
                    window.location.href = link

                    setTimeout(() => {
                        ModalService.closeModal()
                    }, 1000)
                },
                mode
            )
        } else {
            history.push(`/spil/${game.game_id}/${mode}`)
        }
    }
}
const setGamesByEndpoint = (endpoint, data) => {
    switch (endpoint) {
        case 'new':
            newGames.next(data)
            break
        case 'hidden':
            hiddenGames.next(data)
            break
        case 'bonus':
            bonusGames.next(data)
            break
        case 'jackpot':
            jackpotGames.next(data)
            break
        default:
            allGames.next(data)
    }
}
const getIterationLength = (type) => {
    let length = 20

    if (detectMobile()) {
        length = 10
    } else if (type === 'dynamic') {
        length = 14
    } else if (type === 'small') {
        length = 30
    }
    return length
}
const sortGames = (allGames, setAllGames, setGames, method, reversed) => {
    let games = []
    if (method === 'name') {
        games = allGames.sort((a, b) => a.name.localeCompare(b.name))
    } else if (method === 'new') {
        games = allGames.sort((a, b) => {
            return (
                a.go_new - b.go_new ||
                parseFloat(a.go_priority) - parseFloat(b.go_priority) ||
                a.name.localeCompare(b.name)
            )
        })
    } else if (method === 'popular') {
        games = sortingByHotClicks(allGames)
    } else if (method === 'minStake') {
        games = allGames.sort(
            (a, b) =>
                parseFloat(b.min_stake || 0) - parseFloat(a.min_stake || 0) ||
                a.name.localeCompare(b.name)
        )
    } else if (method === 'tbp') {
        allGames.map((game) => {
            if (!game.payback) game.payback = '0'
            return game
        })
        games = allGames.sort(
            (a, b) =>
                parseFloat(b.payback.replace('%', '')) -
                    parseFloat(a.payback.replace('%', '')) ||
                a.name.localeCompare(b.name)
        )
    } else if (method === 'jackpot') {
        allGames.map((game) => {
            if (game.jackpot_amount === null) {
                game.jackpot_amount = -1
            }
            return game
        })
        games = allGames.sort((a, b) => {
            return (
                parseFloat(b.jackpot_amount) - parseFloat(a.jackpot_amount) ||
                a.name.localeCompare(b.name)
            )
        })
    } else {
        games = allGames
    }
    if (reversed === 'true') {
        games = games.reverse()
    }
    setAllGames(games)
    setGames(games.slice(0, getIterationLength()))
}
const fetchOffers = () => {
    UserService.getClientIdValue().then((cmsUser) => {
        if (cmsUser && cmsUser != 0) {
            get(`${CASINOGO_REST_API}offers/client/${cmsUser}`, {
                platform: getPlatformForApi(),
                srv: getEnv(),
            })
                .then((res) => {
                    if (res && res.data) {
                        offers.next(res.data)
                    }
                })
                .catch((error) => {
                    NotificationConductor.error(error.toString())
                })
        } else if (cmsUser === 0) {
            get(`${CASINOGO_REST_API}offers`, {
                platform: getPlatformForApi(),
                srv: getEnv(),
            })
                .then((res) => {
                    if (res && res.data) {
                        offers.next(res.data)
                    }
                })
                .catch((error) => {
                    NotificationConductor.error(error.toString())
                })
        }
    })
}

const getMultipleGameFromTheStore = (game) => {
    const prevValue = multipleGames.value

    if (prevValue.length < MAX_MULTI_GAMES_COUNT) {
        return [...prevValue, game]
    }
}

const updateMultipleGameInTheStore = (key, game) => {
    const prevValue = multipleGames.value

    if (prevValue.length) {
        prevValue[key] = game
        return [...prevValue]
    }
}

const removeMultipleGameFromTheStore = (key = null) => {
    const multipleGamesStore = [...multipleGames.value]

    if (key === null) {
        return []
    } else if (multipleGamesStore.length > 1) {
        multipleGamesStore.splice(key, 1)

        return [...multipleGamesStore]
    }
}

// new recent games
const getRecentGames = (userId) => {
    return CmsREST.getRecentGames({
        site: 'casinogo',
        client_id: userId,
    })
}
const setRecentGame = (gameId, userId) => {
    CmsREST.setRecentGame({
        client_id: userId,
        game_id: gameId.toString(),
    }).catch((error) => NotificationConductor.error(error.toString()))
}
const removeRecentGame = (gameId, userId) => {
    return CmsREST.removeRecentGame({
        client_id: userId,
        game_id: gameId.toString(),
    })
}
const editRecentGames = (gameId, platform) => {
    const userId = localStorage.getItem('userId')
    if (!recentlyPlayedGames.value) {
        prepareRecentGames(editRecentGames, gameId, platform)
    } else {
        const recentGames = recentlyPlayedGames.value.filter(
            (recentGame) => recentGame.platform === platform
        )

        const duplicateGame = recentGames.find(
            (recentGame) => recentGame.id === gameId
        )

        if (duplicateGame) {
            removeRecentGame(duplicateGame.id, userId)
                .then(() => setRecentGame(duplicateGame.id, userId))
                .catch((error) => NotificationConductor.error(error.toString()))
        } else if (recentGames.length >= RECENTLY_PLAYED_GAMES_AMOUNT) {
            const lastRecentGameId = recentGames[recentGames.length - 1].id

            removeRecentGame(lastRecentGameId, userId)
                .then(() => setRecentGame(gameId, userId))
                .catch((error) => NotificationConductor.error(error.toString()))
        } else {
            setRecentGame(gameId, userId)
        }
    }
}

const prepareRecentGames = (cb = () => true, ...args) => {
    const userId = localStorage.getItem('userId')
    getRecentGames(userId)
        .then((res) => {
            if (res.data && res.data.length) {
                let recentGames = []
                const mergeAllGames = [
                    ...allGames.value,
                    ...liveCasinoGames.value.all,
                ]
                const gamesIds = mergeAllGames.map((game) => game.id.toString())

                res.data.forEach((recentGame) => {
                    const gamePos = gamesIds.indexOf(recentGame.game_id)
                    if (gamePos > -1) {
                        recentGames.push(mergeAllGames[gamePos])
                    }
                })
                const gamesData = recentGames
                    .filter((g) => g.go_hide === false)
                    .reverse()
                    .slice(0, RECENTLY_PLAYED_GAMES_AMOUNT)
                recentlyPlayedGames.next(gamesData)
                cb(...args)
            } else {
                recentlyPlayedGames.next([])
                cb(...args)
            }
        })
        .catch((err) => {
            recentlyPlayedGames.next([])
            NotificationConductor.error(err.toString())
        })
}

const setJackpots = (games = [], jackpots = []) => {
    let items = games
    items = items.map((item) => {
        jackpots.length &&
            jackpots.forEach((jackpot) => {
                if (item.game_id === jackpot.game_id) {
                    item.jackpot_amount = jackpot.jackpot_amount
                    item.jackpot_amount_formatted =
                        jackpot.jackpot_amount_formatted
                }
            })
        return item
    })
    return items
}
/**
 * @typedef {Object} Popular params
 * @property {string} limit popular games count
 * @property {number} period period of time for getting games by the clicks
 * @property {boolean} [null] excludeHot remove games marked as hot (rod_hot, go_hot)
 * @property {boolean} [null] rodHot add hot games to response, sorts by clicks, adds rodHot to the first place  (exclude_hot и rod_hot can not work together)
 */
const fetchPopularGames = ({
    limit = 100,
    period = 7,
    excludeHot = null,
    goHot = null,
} = {}) => {
    return new Promise((resolve, reject) => {
        const params = {
            platform: getPlatformForApi(),
            app: APP_NAME,
            ...(typeof excludeHot === 'boolean' && { exclude_hot: excludeHot }),
            ...(typeof goHot === 'boolean' && { go_hot: goHot }),
        }
        get(`${CASINOGO_REST_API}games/popular/${period}/${limit}`, params)
            .then((res) => {
                if (res.data) {
                    const popularGames = res.data
                        .map((game) => {
                            if (!Array.isArray(game.features)) {
                                game.features = game.features.split('|')
                            }
                            return game
                        })
                        .sort(
                            (a, b) =>
                                parseFloat(b.go_hot) - parseFloat(a.go_hot) ||
                                a.name.localeCompare(b.name)
                        )

                    resolve(setJackpots(popularGames, jackpotGames.value))
                }
            })
            .catch((e) => {
                reject(e)
            })
    })
}

const setPopularGames = (gameId, userId) => {
    CmsREST.setPopularGames({
        gameId,
        userId,
        app: `casinogo-${!detectMobile() || detectIpad() ? 'web' : 'mobile'}`,
    }).catch((e) => console.error('Cannot set game to popular: ', e))
}

const getUpcomingGames = () => {
    return get(`${CASINOGO_REST_API}games/upcoming`, {
        platform: getPlatformForApi(),
    })
}

const getAllProvidersGames = () => {
    return combineLatest(
        allGames,
        liveCasinoGames,
        (spilGames, casinoGames) => {
            casinoGames = Object.keys(casinoGames).length ? casinoGames.all : {}
            return [...spilGames, ...casinoGames]
        }
    )
}

const getGameByProvider = (games, gameId) => {
    let game = games.find((g) => g.provider_id === gameId)

    if (game && game.game_id) {
        return game.game_id
    }

    return null
}

const mergeJackpots = (jackpotsInfo, jackpots) => {
    return jackpots.map((item) => {
        let jackpotAmount = item.jackpot_amount

        if (item.subprovider === 'netent' && jackpotsInfo.evolution) {
            const matchEvoGame = jackpotsInfo.evolution.all.find(
                (jackpotEvo) => item.provider_id === jackpotEvo.gameId
            )
            jackpotAmount = matchEvoGame.amount || 0
        }

        if (item.subprovider === 'isoftbet' && jackpotsInfo.isoftbet) {
            jackpotAmount = jackpotsInfo.isoftbet.levels[0].amount || 0
        }

        return {
            ...item,
            jackpot_amount: jackpotAmount,
            jackpot_amount_formatted: jackpotAmount
                ? formatDenmarkCurrent(jackpotAmount)
                : item.jackpot_amount_formatted,
        }
    })
}

export default {
    getGamesState: () => GamesStatus.asObservable(),
    getAllGames: () => allGames.asObservable(),
    getGameByProviderId: (gameId) => getGameByProvider(allGames.value, gameId),
    getGamesConfig: () => gamesConfig.asObservable(),
    getMultipleGames: () => multipleGames.asObservable(),
    getNewGames: () => newGames.asObservable(),
    getHiddenGames: () => hiddenGames.asObservable(),
    getBonusGames: () => bonusGames.asObservable(),
    getGameLaunchState: () => isGameLaunched.asObservable(),
    getLiveCasinoGames: () => liveCasinoGames.asObservable(),
    getJackpotGames: () => jackpotGames.asObservable(),
    getFavoriteGames: () => favoriteGames.asObservable(),
    resetFavoriteGames: () => favoriteGames.next([]),
    getCategorizedGames: () => categorizedGames.asObservable(),
    getCategorizedGamesSubject: () => categorizedGames,
    getGamesCategories: () => gamesCategories.asObservable(),
    getOffers: () => offers.asObservable(),

    fetchOffers,
    fetchGamesCategories: () => {
        get(`${CASINOGO_REST_API}categories`, {
            platform: getPlatformForApi(),
            srv: getEnv(),
        })
            .then((res) => {
                fetchPopularGames() // todo remove after update popular games count
                    .then((games) => {
                        const categories = res.data.map((categorie) => {
                            if (categorie.type === 'popular') {
                                categorie.games_count = games.length
                            }
                            return categorie
                        })
                        gamesCategories.next(categories)
                    })
                    .catch((e) => gamesCategories.next(res.data))
            })
            .catch((error) => {
                NotificationConductor.error(error.toString())
            })
    },
    fetchFavorite: (id = null) => {
        const userId = parseInt(localStorage.getItem('userId'))
        if (userId || id) {
            get(`${CASINOGO_REST_API}clients/${userId || id}/games`, {
                platform: getPlatformForApi(),
                srv: getEnv(),
            })
                .then((res) => {
                    if (res.error) {
                        NotificationConductor.error(res.error)
                    } else {
                        const preparedGames = setJackpots(
                            res.data,
                            jackpotGames.value
                        ).filter((g) => g.go_hide === false)

                        favoriteGames.next(preparedGames)
                    }
                })
                .catch((error) => {
                    NotificationConductor.error(error.toString())
                })
        }
    },
    fetchCombinedJackpots: () => {
        return Promise.allSettled([
            get(`${CASINOGO_REST_API}categories/jackpots/games`, {
                srv: getEnv(),
                platform: getPlatformForApi(),
            }),
            get(`${CASINOGO_REST_API}casino/content/jackpot/all`),
        ])
            .then((results) => {
                const [jackpotsResult, jackpotsInfo] = results

                if (jackpotsResult.status !== 'fulfilled') {
                    throw new Error('Failed to fetch jackpot games')
                }

                const jackpotGamesData = jackpotsResult.value.data

                const mergedData =
                    jackpotsInfo.status === 'fulfilled'
                        ? mergeJackpots(
                              jackpotsInfo.value.data,
                              jackpotGamesData
                          )
                        : jackpotGamesData

                jackpotGames.next(mergedData)
            })
            .catch((err) => NotificationConductor.error(err.toString()))
    },
    fetchGames: (endpoint = '') => {
        GamesStatus.next({ pending: true, success: false, error: '' })
        const fetchEndpoint = endpoint ? '/' + endpoint : ''
        fetchGames(fetchEndpoint)
            .then((res) => {
                GamesStatus.next({ pending: false, success: true, error: '' })
                const preparedGames = setJackpots(res.data, jackpotGames.value)
                setGamesByEndpoint(endpoint, preparedGames)
            })
            .catch((err) => {
                NotificationConductor.error(err)
                GamesStatus.next({ pending: false, success: false, error: err })
            })
    },
    fetchGamesByCategory: (category) => {
        GamesStatus.next({ pending: true, success: false, error: '' })
        get(`${CASINOGO_REST_API}categories/${category}/games`, {
            srv: getEnv(),
            platform: getPlatformForApi(),
        })
            .then((res) => {
                GamesStatus.next({ pending: false, success: true, error: '' })
                const preparedGames = setJackpots(res.data, jackpotGames.value)
                categorizedGames.next(preparedGames)
            })
            .catch((err) => {
                NotificationConductor.error(err.toString())
                GamesStatus.next({ pending: false, success: false, error: err })
            })
    },
    fetchLiveCasino: () => {
        GamesStatus.next({ pending: true, success: false, error: '' })
        get(`${CASINOGO_REST_API}categories/liveint/games`, {
            srv: getEnv(),
            platform: getPlatformForApi(),
        })
            .then((res) => {
                GamesStatus.next({ pending: false, success: true, error: '' })
                liveCasinoGames.next(mapLiveGames(res.data))
            })
            .catch((err) => {
                NotificationConductor.error(err.toString())
                GamesStatus.next({ pending: false, success: false, error: err })
            })
    },
    fetchGameById: (id) =>
        get(`${CASINOGO_REST_API}games/${id}`, {
            srv: getEnv(),
            platform: getPlatformForApi(),
        }),

    setGameLaunchState: (state) => isGameLaunched.next(state),

    setMultipleGames: (game) =>
        multipleGames.next(getMultipleGameFromTheStore(game)),

    removeMultipleGames: (key) =>
        multipleGames.next(removeMultipleGameFromTheStore(key)),

    updateMultipleGames: (key, game) =>
        multipleGames.next(updateMultipleGameInTheStore(key, game)),

    searchGames: (searchString) =>
        get(
            CASINOGO_REST_API +
                `games/smart-search?srv=${getEnv()}&platform=${getPlatformForApi()}&` +
                searchString
        ),
    fetchProvidersGames: () => {
        get(`${CASINOGO_REST_API}providers`, {
            srv: getEnv(),
            platform: getPlatformForApi(),
            include_hidden: getEnvWithBeta() === 'test',
        }).then((res) => {
            if (res.data) {
                const mappedProvidersConfigs = res.data.map((provider) => {
                    let item = providersData.find(
                        (providerItem) => providerItem.name === provider.name
                    )
                    return provider ? { ...provider, ...item } : item
                })
                providersGames.next(
                    mappedProvidersConfigs.filter(
                        (provider) => provider.name !== 'gdm'
                    )
                ) // exclude gdm because it's combined with SG
            }
        })
    },
    getProvidersGames: () => providersGames.asObservable(),
    getGameLaunchUrl,
    gameLike,
    gameUnLike,
    sortGames,
    getIterationLength,
    LaunchGame,
    checkLoginBeforeStart,
    setRecentlyPlayedGames: () => prepareRecentGames(),
    getRecentlyPlayedGames: () => recentlyPlayedGames.asObservable(),
    pushGameToRecentlyPlayed: (game) => editRecentGames(game.id, game.platform),
    prepareJackpotsGame: (games) => setJackpots(games, jackpotGames.value),
    setMinimizedGamePosition: (gameData) => {
        const prevData = minimizedGamesData.value
        minimizedGamesData.next({
            ...prevData,
            gamesPosition: {
                ...prevData.gamesPosition,
                ...gameData,
            },
        })
    },
    setMinimizedGames: (game, resetPrevGames = false) => {
        const prevData = minimizedGamesData.value
        if (prevData && prevData.games && !resetPrevGames) {
            if (
                !prevData.games.some(
                    (prevGame) => prevGame.game_id === game.game_id
                ) &&
                Object.values(game).length
            ) {
                minimizedGamesData.next({
                    ...prevData,
                    games: [...prevData.games, game], // todo here is the issue
                })
            }
        } else {
            minimizedGamesData.next({
                ...prevData,
                games: [game],
            })
        }
    },
    setMinimizeStates: (state) =>
        minimizedGamesData.next({ ...minimizedGamesData.value, ...state }),
    getMinimizedGame: () => minimizedGamesData.asObservable(),
    removeMinimizedGame: (gameId = null) => {
        let minimizedGamesStore = minimizedGamesData.value.games
        const key = minimizedGamesStore.findIndex(
            (game) => game.game_id === gameId
        )
        if (minimizedGamesStore.length) {
            minimizedGamesStore[key] = {}
            minimizedGamesData.next({
                ...minimizedGamesData.value,
                games: [...minimizedGamesStore],
            })
        }

        const gamesPosition = minimizedGamesData.value.gamesPosition
        if (gamesPosition[gameId]) {
            delete gamesPosition[gameId]
            minimizedGamesData.next({
                ...minimizedGamesData.value,
                gamesPosition: {
                    ...gamesPosition,
                },
            })
        }
    },
    cleanMinimizedData: () => minimizedGamesData.next({}),
    getEndGame: () => get(`${endGameUrl.value}`),
    fetchPopularGames,
    setPopularGames,
    getUpcomingGames,
    getAllProvidersGames,
}
