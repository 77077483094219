import React, { useState } from 'react'
import closeButton from 'icons/close_white.png'
import { ModalService } from 'services/ModalService'
import moment from 'moment'
import Slider from 'react-slick'
import { SLIDER_SETTINGS } from 'configs/ui'
import Icon from 'ui/Icon'
import play from 'icons/white-play.png'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import {
    Arrow,
    StyledWrapper,
    SliderWrapper,
    Slide,
    ClosePopup,
    Title,
    VideoIframe,
    LoaderWrapper,
} from 'features/VideoPopup/styled'

const PrevArrow = (props) => (
    <Arrow onClick={props.onClick}>
        <Icon
            width="8px"
            height="12px"
            src={play}
            style={{ transform: 'rotate(180deg)' }}
        />
    </Arrow>
)
const NextArrow = (props) => (
    <Arrow onClick={props.onClick}>
        <Icon width="8px" height="12px" src={play} />
    </Arrow>
)

const VideoPopup = ({ data }) => {
    const [loaded, setLoaded] = useState(false)
    const { initialSlide, games } = data
    const closePopup = () => {
        ModalService.closeModal('VIDEO_POPUP')
    }
    const settings = {
        ...SLIDER_SETTINGS,
        slidesToShow: 1,
        initialSlide,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: false,
    }
    return (
        <StyledWrapper>
            <SliderWrapper>
                <ClosePopup onClick={closePopup}>
                    <img src={closeButton} alt="verificationClose" />
                </ClosePopup>
                <Slider {...settings}>
                    {games.map((game, index) => {
                        const { videoUrl, name, release } = game
                        return (
                            <Slide key={index}>
                                <Title>
                                    {name} - udkommer d.{' '}
                                    {moment(release).format('DD. MMMM')}
                                </Title>
                                {!loaded && (
                                    <LoaderWrapper>
                                        <Loader color={BACKGROUNDS.primary} />
                                    </LoaderWrapper>
                                )}
                                <VideoIframe
                                    src={videoUrl}
                                    name="videoIframe"
                                    id={`videoEmbed`}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    onLoad={() => setLoaded(true)}
                                />
                            </Slide>
                        )
                    })}
                </Slider>
            </SliderWrapper>
        </StyledWrapper>
    )
}

export default VideoPopup
