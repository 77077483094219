import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
    ControlIcon,
    Embed,
    LoaderHolder,
    MultipleGamesRatio,
    MultiGamesControls,
    MultiGamesFrame,
    MultiGamesSearchFrame,
    StyledIframe,
    ControlFrameWrapper,
    GameAreaToolTip,
} from 'features/GameArea/styled'
import GameAreaSearch from './Search'
import Text from 'ui/Text'
import closeIcon from 'icons/close.svg'
import { BACKGROUNDS } from 'themes/index'
import starActiveIcon from 'icons/star_active.svg'
import { ModalService } from 'services/ModalService'
import infoIcon from 'icons/info.svg'
import searchIcon from 'icons/search.svg'
import GamesService from 'services/GamesService'
import { startGame } from 'services/GameLaunchService'
import gamesService from 'services/GamesService'
import Loader from 'ui/Loader'
import { isFunction } from 'utils/object-helper'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'

export function getScript(source, callback) {
    var script = document.createElement('script')
    var prior = document.getElementsByTagName('script')[0]
    script.async = 1

    script.onload = script.onreadystatechange = function(_, isAbort) {
        if (
            isAbort ||
            !script.readyState ||
            /loaded|complete/.test(script.readyState)
        ) {
            script.onload = script.onreadystatechange = null
            script = undefined

            if (!isAbort && callback) setTimeout(callback, 0)
        }
    }

    script.src = source
    prior.parentNode.insertBefore(script, prior)
}

const MultipleGames = ({
    index,
    game,
    mode,
    fullScreen,
    sizeChange,
    favoriteGames,
    toggleGameLike,
    multiScreenMode,
    gamesForMultiScreen,
    removeGameFromMultiple,
    isUserLoggedIn,
    refsList,
    minimizedGameList,
}) => {
    const [launchUrl, setLaunchUrl] = useState('')
    const [load, setLoad] = useState(false)
    const [isGameActive, setIsGameActive] = useState(false)
    const [node, setNode] = useState(null)

    const newRef = useCallback(
        (node) => {
            if (node !== null && game.game_id) {
                refsList(node, game.game_id)
                setNode(node)
            }
        },
        [multiScreenMode, gamesForMultiScreen, fullScreen, sizeChange]
    )

    const isFavorite = (gameId) => {
        return favoriteGames.map((e) => e.game_id).indexOf(gameId) >= 0
    }

    const launchGame = (launchUrl) => {
        if (launchUrl) {
            setLaunchUrl(launchUrl)
            gamesService.setGameLaunchState(true)
        }
    }

    const enableSearch = () => {
        if (isGameActive) {
            setIsGameActive(false)
            GamesService.updateMultipleGames(index, {})
            GamesService.removeMinimizedGame(game.game_id)
        }
    }

    const closeModal = (modalName = '') => {
        isFunction(close)
            ? close(modalName)
            : ModalService.closeModal(modalName)
    }

    useEffect(
        () => {
            if (game.game_id && detectMobile()) {
                setIsGameActive(true)
                startGame(
                    game,
                    (link) => {
                        launchGame(link)
                    },
                    mode
                )
            }
        },
        [game]
    )

    useEffect(
        () => {
            if (
                game.game_id &&
                minimizedGameList.some(
                    (miniGames) => miniGames.game_id === game.game_id
                )
            ) {
                setIsGameActive(true)
            }
        },
        [multiScreenMode]
    )

    useEffect(
        () => {
            if (detectMobile()) {
                if (mode === 'money' && !isUserLoggedIn) {
                    closeModal()
                }
                if (launchUrl && launchUrl.includes('playngonetwork')) {
                    getScript(launchUrl, (res) => {
                        setLoad(false)
                    })
                } else if (Object.keys(game).length) {
                    setLoad(true)
                }
            }
        },
        [launchUrl]
    )

    useEffect(
        () => {
            if (node) {
                const mesure = () => {
                    refsList(node, game.game_id)
                }
                window.addEventListener('resize', mesure)
                return () => {
                    window.removeEventListener('resize', mesure)
                }
            }
        },
        [node]
    )
    return (
        <Fragment>
            {load && (
                <LoaderHolder my={3}>
                    <Loader color={BACKGROUNDS.primary} />
                </LoaderHolder>
            )}
            <MultipleGamesRatio
                multiScreenMode={multiScreenMode}
                show={!isGameActive || multiScreenMode}
                fullScreen={fullScreen}
                ref={newRef}
            >
                {Object.keys(game).length > 0 ? (
                    (detectMobile() || multiScreenMode) &&
                    launchUrl &&
                    !launchUrl.includes('playngonetwork') ? (
                        <StyledIframe
                            sizeChange={sizeChange}
                            src={launchUrl}
                            onLoad={(e) => {
                                setLoad(false)
                            }}
                            name="gameIframe"
                            id={`gameEmbed_${index}`}
                        />
                    ) : (
                        <Embed id={game.game_id} sizeChange={sizeChange} />
                    )
                ) : (
                    <GameAreaSearch
                        index={index}
                        gamesForMultiScreen={gamesForMultiScreen}
                    />
                )}
                {multiScreenMode && (
                    <MultiGamesControls fullScreen={fullScreen}>
                        <ControlFrameWrapper>
                            <MultiGamesFrame
                                fullWidth="120px"
                                onClick={() =>
                                    removeGameFromMultiple(index, game.game_id)
                                }
                            >
                                <ControlIcon
                                    width="18px"
                                    height="18px"
                                    src={closeIcon}
                                />
                            </MultiGamesFrame>
                            <GameAreaToolTip>Luk spil</GameAreaToolTip>
                        </ControlFrameWrapper>
                        <ControlFrameWrapper
                            disabled={!Object.keys(game).length}
                        >
                            <MultiGamesFrame
                                onClick={
                                    game.game_id
                                        ? () => toggleGameLike(game.game_id)
                                        : null
                                }
                            >
                                <ControlIcon
                                    width="18px"
                                    height="18px"
                                    color={
                                        isFavorite(game.game_id)
                                            ? BACKGROUNDS.accent
                                            : BACKGROUNDS.white
                                    }
                                    src={starActiveIcon}
                                />
                            </MultiGamesFrame>
                            <GameAreaToolTip>
                                {isFavorite(game.game_id)
                                    ? 'Fjern favoritter'
                                    : 'Tilføj favoritter'}
                            </GameAreaToolTip>
                        </ControlFrameWrapper>
                        <ControlFrameWrapper
                            disabled={!Object.keys(game).length}
                        >
                            <MultiGamesFrame
                                onClick={() => {
                                    ModalService.showModal('GAME_POPUP', {
                                        ...game,
                                        backUrl: window.location.href,
                                    })
                                    window.history.pushState(
                                        '',
                                        '',
                                        '/spil/info/' + game.id
                                    )
                                }}
                            >
                                <ControlIcon
                                    color={'white'}
                                    width="18px"
                                    height="18px"
                                    src={infoIcon}
                                />
                            </MultiGamesFrame>
                            <GameAreaToolTip>Vis information</GameAreaToolTip>
                        </ControlFrameWrapper>
                        <ControlFrameWrapper>
                            <MultiGamesSearchFrame
                                className={!isGameActive ? 'active' : null}
                                onClick={() => enableSearch(true)}
                            >
                                <ControlIcon
                                    width="18px"
                                    height="18px"
                                    src={searchIcon}
                                />
                            </MultiGamesSearchFrame>
                        </ControlFrameWrapper>
                    </MultiGamesControls>
                )}
            </MultipleGamesRatio>
        </Fragment>
    )
}

export default MultipleGames
