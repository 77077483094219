import React, { useCallback, useEffect, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import { SLIDER_SETTINGS } from 'configs/ui'
import yggdrasilLogo from 'images/providers/yggdrasil.svg'
import netentLogo from 'images/providers/netent.svg'
import playngoLogo from 'images/providers/playngo.svg'
import isoftbetLogo from 'images/providers/isoftbet.svg'
import evolutionLogo from 'images/providers/evolution.svg'
import elkLogo from 'images/providers/elk.svg'
import quickspinLogo from 'images/providers/quickspin.svg'
import wazdanLogo from 'images/providers/wazdan.svg'
import thunderkickLogo from 'images/providers/thunderkick.svg'
import scientificLogo from 'images/providers/scientific.svg'
import btgLogo from 'images/providers/btg.svg'
import lightboxLogo from 'images/providers/lightbox.svg'
import hacksawLogo from 'images/providers/hacksaw.svg'
import nolimitLogo from 'images/providers/nolimit.svg'
import redtigerLogo from 'images/providers/redtiger.svg'
import oneXtwoLogo from 'images/providers/1X2.svg'
import blueprintLogo from 'images/providers/blueprint.svg'
import pragmaticLogo from 'images/providers/pragmatic.svg'
import relaxLogo from 'images/providers/relax.svg'
import styled from 'styled-components'
import { BACKGROUNDS, COLORS } from 'themes'
import { detectMobile } from 'common/adapters/DeviceDetectAdapter'
import device from 'utils/mediaQueries'
import Wrapper from 'ui/Wrapper'
import GamesService from 'services/GamesService'
import { useHistory } from 'react-router'
import getTexts from 'utils/localization'
import { MoreLink } from '../GamesSlider/styled'

const t = getTexts()

const PROVIDERS = {
    relax: relaxLogo,
    pragmatic: pragmaticLogo,
    blueprint: blueprintLogo,
    '1x2': oneXtwoLogo,
    redtiger: redtigerLogo,
    scientific: scientificLogo,
    btg: btgLogo,
    lightbox: lightboxLogo,
    netent: netentLogo,
    playngo: playngoLogo,
    nolimit: nolimitLogo,
    wazdan: wazdanLogo,
    isoftbet: isoftbetLogo,
    thunderkick: thunderkickLogo,
    yggdrasil: yggdrasilLogo,
    evolution: evolutionLogo,
    elk: elkLogo,
    hacksaw: hacksawLogo,
    quickspin: quickspinLogo,
}
const Slide = styled.div`
    background-color: ${BACKGROUNDS.secondary};
    border-radius: 5px;
    text-align: center;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        max-width: 40%;
        max-height: 130px;
        margin: 0 auto;
    }

    p {
        color: ${COLORS.white};
        font-size: 18px;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
    }
`

const SlideWrapper = styled.div`
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
`
const MobileSlideWrapper = styled(SlideWrapper)`
    position: relative;
    display: inline-block;
    padding: 5px;
    box-sizing: border-box;
    width: 33%;
    height: 200px;
    vertical-align: middle;
    @media screen and ${device.lg} {
        width: 33%;
    }
    @media screen and ${device.sm} {
        width: 50%;
    }
    @media screen and ${device.sm} {
        img {
            max-width: 60%;
        }
    }
`
const ProvidersList = () => {
    const settings = {
        ...SLIDER_SETTINGS,
    }
    const [providers, setProviders] = useState([])
    const [dragging, setDragging] = useState(false)
    const history = useHistory()

    const handleBeforeChange = useCallback(
        () => {
            setDragging(true)
        },
        [setDragging]
    )
    const handleAfterChange = useCallback(
        () => {
            setDragging(false)
        },
        [setDragging]
    )

    const handleOnItemClick = (provider, e) => {
        if (dragging) e.stopPropagation()
        else {
            history.push({
                pathname: `/providers/${provider.name}`,
                state: { provider },
            })
        }
    }

    useEffect(() => {
        GamesService.getProvidersGames().subscribe((res) => {
            res ? setProviders(res) : null
        })
    }, [])

    return providers.length ? (
        <Wrapper padding="0">
            <HeadingWithBadge
                margin="0 0 20px 20px"
                bold
                display={!detectMobile() && 'inline-flex'}
            >
                {t.providers}
            </HeadingWithBadge>
            {!detectMobile() && (
                <MoreLink
                    to={'/alle-spil/spiludviklere'}
                    top="20px"
                    right="140px"
                >
                    Alle
                </MoreLink>
            )}
            {detectMobile() ? (
                providers.map((el) => (
                    <MobileSlideWrapper
                        key={el.name}
                        onClick={(e) => {
                            handleOnItemClick(el, e)
                        }}
                    >
                        <Slide>
                            <img src={PROVIDERS[el.name]} alt={el.name} />
                            <p>
                                {el.games && el.games.count} {t.games}
                            </p>
                        </Slide>
                    </MobileSlideWrapper>
                ))
            ) : (
                <Slider
                    {...settings}
                    beforeChange={handleBeforeChange}
                    afterChange={handleAfterChange}
                >
                    {providers.map((el) => (
                        <SlideWrapper
                            key={el.provider}
                            onClick={(e) => {
                                handleOnItemClick(el, e)
                            }}
                        >
                            <Slide
                                beforeChange={handleBeforeChange}
                                afterChange={handleAfterChange}
                            >
                                <img src={PROVIDERS[el.name]} alt={el.name} />
                                <p>
                                    {el.games.count} {t.games}
                                </p>
                            </Slide>
                        </SlideWrapper>
                    ))}
                </Slider>
            )}
        </Wrapper>
    ) : null
}
export default ProvidersList
