import React, { useEffect, useState } from 'react'
import moment from 'moment/moment'
import styled, { keyframes } from 'styled-components'
import { byPlatform } from 'common/adapters/DeviceDetectAdapter'
import UserService from 'services/UserService'
import Icon from 'ui/Icon'
import closeIcon from 'images/icons/close-light.svg'
import { COLORS } from 'themes'

// Slide Up Animation
const slideUp = keyframes`
    0% {
        transform: ${byPlatform(
            'translate(-50%, -140px)',
            'translate(-50%, 100%)'
        )};
        opacity: 0;
    }
    60% {
        transform: ${byPlatform(
            'translate(-50%, 5px)',
            'translate(-50%, -5px)'
        )};
        opacity: 1;
    }
    80% {
        transform: ${byPlatform(
            'translate(-50%, -2px)',
            'translate(-50%, 2px)'
        )};
    }
    100% {
        transform: translate(-50%, 0);
    }
`

// Slide Down Animation
const slideDown = keyframes`
    0% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
    100% {
        transform: ${byPlatform(
            'translate(-50%, -140px)',
            ' translate(-50%, 100%)'
        )};
        opacity: 0;
    }
`

const Popup = styled.div`
    border: 1px solid #e4e9ef;
    background: #f5f8fc;
    color: #1e1e1e;
    font-size: ${byPlatform('10px', '13px')};
    width: ${byPlatform('calc(100% - 40px)', '461px')};
    box-sizing: border-box;
    padding: ${byPlatform('8px 20px', '12px 8px 12px 32px')};
    display: flex;
    justify-content: ${byPlatform('center', 'space-between')};
    align-items: center;
    height: 40px;
    position: fixed;
    bottom: ${byPlatform('auto', '15px')};
    top: ${byPlatform('40px', 'auto')};
    left: 50%;
    z-index: 999;
    border-radius: 4px;
    animation: ${({ fadeOut }) => (fadeOut ? slideDown : slideUp)} 0.5s ease-out
        forwards;
    animation-direction: normal;

    & span.login-time {
        font-weight: bold;
    }

    & img {
        width: 24px;
        height: 24px;
        margin-left: 30px;
        cursor: pointer;
    }
`

const StyledIcon = styled(Icon)`
    cursor: pointer;
`

const LastLoginPopup = () => {
    const [visible, setVisible] = useState(false)
    const [fadeOut, setFadeOut] = useState(false)
    const [lastLoginTime, setLastLoginTime] = useState('')

    useEffect(() => {
        let fadeoutTimer, closeTimer

        const subscription = UserService.getUser().subscribe((user) => {
            setFadeOut(false)

            if (
                user &&
                Object.keys(user).length &&
                !UserService.isLastLoginShown()
            ) {
                if (!user.lastLogin) {
                    return
                }

                setLastLoginTime(user.lastLogin)
                setVisible(true)

                fadeoutTimer = setTimeout(() => {
                    setFadeOut(true)
                }, 4400)
                closeTimer = setTimeout(() => {
                    setVisible(false)
                    UserService.setLastLoginPopupState(true)
                }, 5000)
            }
        })

        return () => {
            clearTimeout(fadeoutTimer)
            clearTimeout(closeTimer)
            subscription.unsubscribe()
        }
    }, [])

    const handleClose = () => {
        setFadeOut(true)
        setTimeout(() => {
            setVisible(false)
            UserService.setLastLoginPopupState(true)
        }, 500)
    }

    if (!visible) return null

    return (
        <Popup fadeOut={fadeOut}>
            <div>
                Velkommen tilbage. Du var sidst logget ind{' '}
                <span className="login-time">{lastLoginTime}</span>
            </div>
            {byPlatform(
                null,
                <StyledIcon
                    onClick={handleClose}
                    src={closeIcon}
                    width="15px"
                    color={COLORS.secondary}
                />
            )}
        </Popup>
    )
}

export default LastLoginPopup
