import React from 'react'
import { TransactionDetails as TransactionDetailsComp } from '@it25syv/25syv-ui'
import { ModalService } from 'services/ModalService'

const TransactionDetails = (props) => {
    return (
        <TransactionDetailsComp
            currentTheme={'dark'}
            data={props.data}
            onClose={() => ModalService.closeModal('TRANSACTION_INFO')}
        />
    )
}
export default TransactionDetails
