import Login from 'features/Login'
import LoginForgotPass from 'features/Login/LoginForgotPass'
import RestorePass from 'features/Login/RestorePass'
import GamePopup from 'features/GamePopup'
import UserArea from 'features/UserArea'
import YourInfo from 'features/UserArea/Components/YourInfo'
import Voucher from 'features/UserArea/Components/Voucher'
import UserHistory from 'features/UserHistory'
import PaymentHistory from 'features/UserHistory/Components/PaymentHistory'
import PlayHistory from 'features/UserHistory/Components/PlayHistory'
import UserDeposits from 'features/UserDeposits'
import PinLogin from 'features/PinLogin'
import PinLoginPopup from 'features/PinLogin/PinLoginPopup'
import DepositLimit from 'features/DepositLimit'
import SelfExclusion from 'features/SelfExclusion'
import SelfExcludedTextModal from 'features/SelfExclusion/SelfExcludedTextModal'
import Withdraw from 'features/Withdraw/'
import ForgotPassword from 'features/ForgotPassword'
import SignUp from 'features/SignUp'
import TextPage from '@/layouts/sections/LeftSidebar/TextPage'
import UpdatePassword from '@/features/UserArea/Components/UpdatePassword'
import CampaignPopup from 'features/CampaignPopup'
import { withAnimation } from 'ui/WithAnimation'
import LeftSidebarContainer from '@/layouts/sections/LeftSidebar'
import StatusOfAction from 'features/StatusOfAction'
import SettingsModal from 'features/SettingsModal'
import FavoritesPopup from 'features/FavoritesPopup'
import RateUs from 'features/RateUs'
import ClientServise from 'features/ClientServise'
import ResponsibleGaming from 'features/ResponsibleGamingModal'
import SearchModal from 'features/SearchModal'
import GoPopup from 'features/GoPopup'
import BonusPopup from 'features/UserArea/Components/BonusPopup'
import PasswordsModal from 'features/PasswordsModal'
import FastDepositLimit from 'features/DepositLimit/FastDepositLimit'
import WarningPopup from 'features/WarningPopup'
import SmartFilters from 'features/SmartFilters/SmartFilters'
import SearchFilters from 'features/SearchModal/SearchFilters'
import CategoryResult from 'features/SearchModal/CategoryResult'
import SessionPopup from 'features/SessionPopup'
import VerificationPopup from 'features/VerificationPopup'
import DepositLimitPopup from 'features/DepositLimitPopup'
import VideoPopup from 'features/VideoPopup'
import CPRConfirmation from 'features/Login/CPRConfirmation'
import EmailAlreadyExist from 'features/SignUp/EmailAlreadyExist'
import WithdrawNotificationPopup from 'features/Withdraw/WithdrawNotificationPopup'
//import WithdrawRemoveBonusPopup from 'features/Withdraw/WithdrawRemoveBonusPopup'
import RemoveBonusConfirmModal from 'features/Withdraw/RemoveBonusConfirmModal'
import TransactionDetails from 'features/UserHistory/TransactionDetails'
import TransactionChooseDate from 'features/UserHistory/TransactionChooseDate'

const ModalsMap = [
    {
        name: 'LOGIN',
        type: 'modal',
        component: Login,
    },
    {
        name: 'LOGIN_FORGOT_PASS',
        type: 'modal',
        component: LoginForgotPass,
    },
    {
        name: 'RESTORE_PASS',
        type: 'modal',
        component: RestorePass,
    },
    {
        name: 'GAME_POPUP',
        type: 'modal',
        component: withAnimation(GamePopup),
    },
    {
        name: 'CAMPAIGN_POPUP',
        type: 'popup',
        component: CampaignPopup,
    },
    {
        name: 'SEARCH_MODAL',
        type: 'modal',
        component: withAnimation(SearchModal, 'left'),
    },
    {
        name: 'USER_AREA',
        type: 'modal',
        component: withAnimation(UserArea),
    },
    {
        name: 'YOUR_INFO',
        type: 'modal',
        component: withAnimation(YourInfo),
    },
    {
        name: 'VOUCHER',
        type: 'modal',
        component: withAnimation(Voucher),
    },
    {
        name: 'PASSWORDS',
        type: 'modal',
        component: withAnimation(PasswordsModal),
    },
    {
        name: 'UPDATE_PASSWORD',
        type: 'modal',
        component: withAnimation(UpdatePassword),
    },
    {
        name: 'TRANSACTION_HISTORY',
        type: 'modal',
        component: withAnimation(UserHistory),
    },
    {
        name: 'TRANSACTION_INFO',
        type: 'popup',
        component: TransactionDetails,
    },
    {
        name: 'TRANSACTION_CHOOSE_DATE',
        type: 'popup',
        component: TransactionChooseDate,
    },
    {
        name: 'USER_DEPOSIT',
        type: 'modal',
        component: withAnimation(UserDeposits),
    },
    {
        name: 'PIN_LOGIN',
        type: 'modal',
        component: withAnimation(PinLogin),
    },
    {
        name: 'PIN_LOGIN_POPUP',
        type: 'popup',
        component: withAnimation(PinLoginPopup),
    },
    {
        name: 'DEPOSIT_LIMIT',
        type: 'modal',
        component: withAnimation(DepositLimit),
    },
    {
        name: 'SELF_EXCLUSION',
        type: 'modal',
        component: withAnimation(SelfExclusion),
    },
    {
        name: 'SELF_EXCLUSION_TEXT',
        type: 'modal',
        component: withAnimation(SelfExcludedTextModal),
    },
    {
        name: 'WITHDRAW',
        type: 'modal',
        component: withAnimation(Withdraw),
    },
    {
        name: 'WITHDRAW_NOTIFICATION_POPUP',
        type: 'pop',
        component: WithdrawNotificationPopup,
    },
    {
        name: 'REMOVE_BONUS_CONFIRM',
        type: 'popup',
        component: RemoveBonusConfirmModal,
    },
    {
        name: 'FAST_DEPOSIT_LIMIT',
        type: 'modal',
        component: withAnimation(FastDepositLimit),
    },
    {
        name: 'BONUS_POPUP',
        type: 'popup',
        component: withAnimation(BonusPopup),
    },
    {
        name: 'WARNING_POPUP',
        type: 'popup',
        component: WarningPopup,
    },
    {
        name: 'FORGOT_PASSWORD',
        type: 'modal',
        component: withAnimation(ForgotPassword),
    },
    {
        name: 'SIGN_UP',
        type: 'modal',
        component: withAnimation(SignUp),
    },
    {
        name: 'TEXT_PAGE',
        type: 'modal',
        component: withAnimation(TextPage, 'left'),
    },
    {
        name: 'RATE_US',
        type: 'modal',
        component: withAnimation(RateUs, 'left'),
    },
    {
        name: 'RATE_US_RIGHT',
        type: 'modal',
        component: withAnimation(RateUs),
    },
    {
        name: 'CLIENT_SERVISE',
        type: 'modal',
        component: withAnimation(ClientServise, 'left'),
    },
    {
        name: 'CLIENT_SERVISE_RIGHT',
        type: 'modal',
        component: withAnimation(ClientServise),
    },
    {
        name: 'UPDATE_PASSWORD',
        type: 'modal',
        component: withAnimation(UpdatePassword),
    },
    {
        name: 'LEFT_SIDEBAR',
        type: 'modal',
        component: withAnimation(LeftSidebarContainer, 'left'),
    },
    {
        name: 'STATUS_OF_ACTION',
        type: 'modal',
        component: withAnimation(StatusOfAction),
    },
    {
        name: 'SETTINGS',
        type: 'modal',
        component: withAnimation(SettingsModal),
    },
    {
        name: 'FAVORITES',
        type: 'modal',
        component: withAnimation(FavoritesPopup),
    },
    {
        name: 'RESPONSIBLE_GAMING',
        type: 'modal',
        component: withAnimation(ResponsibleGaming),
    },
    {
        name: 'GO_POPUP',
        type: 'popup',
        component: GoPopup,
    },
    {
        name: 'SMART_FILTERS',
        type: 'modal',
        component: withAnimation(SmartFilters),
    },
    {
        name: 'SEARCH_FILTERS',
        type: 'modal',
        component: withAnimation(SearchFilters, 'left'),
    },
    {
        name: 'SEARCH_RESULT',
        type: 'modal',
        component: withAnimation(CategoryResult, 'left'),
    },
    {
        name: 'SEARCH_RESULT_MOBILE',
        type: 'modal',
        component: withAnimation(CategoryResult),
    },
    {
        name: 'SESSION_EXPIRED',
        type: 'modal',
        component: SessionPopup,
    },
    {
        name: 'VERIFICATION_POPUP',
        type: 'popup',
        component: VerificationPopup,
    },
    {
        name: 'DEPOSIT_LIMIT_WARNING',
        type: 'popup',
        component: DepositLimitPopup,
    },
    {
        name: 'VIDEO_POPUP',
        type: 'popup',
        component: VideoPopup,
    },
    {
        name: 'EMAIL_EXIST',
        type: 'popup',
        component: EmailAlreadyExist,
    },
    {
        name: 'CPR_CONFIRMATION',
        type: 'popup',
        component: CPRConfirmation,
    },
]

export default ModalsMap
