import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { BACKGROUNDS } from 'themes'
import CloseModalButton from '@/layouts/sections/Modals/CloseModalButton'
import GameInfoWrapper from './Components/GameInfoWrapper'
import MainActionsRow from './Components/MainActionsRow'
import ProviderInfo from './Components/ProviderInfo'
import InfoList from './Components/InfoList'
import gameLogoTemp from 'images/icons/top-bar/casino-go-logo.svg'
import GamesService from 'common/Services/GamesService'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { useHistory } from 'react-router-dom'
import UserService from 'services/UserService'
import { Headline5 } from 'ui/Headline'
import Wrapper from 'ui/Wrapper'
import { useOutsideDetector } from 'common/Hooks/UseOutsideDetector'
import { isFunction } from 'utils/object-helper'
import getTexts from 'utils/localization'
import { SMART_FILTERS } from 'common/categories'
import Button from 'ui/controls/Button'
import CardImage from 'ui/CardImage'
import { JackpotWrapper } from 'ui/JackpotWrapper'
import Text from 'ui/Text'

const t = getTexts()

const StyledGamePopupWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 3px;
    box-sizing: border-box;
    background: ${BACKGROUNDS.secondary};
`

const GameCardWrapper = styled.div`
    position: relative;
`

const StyledPopupContent = styled.div`
    padding: 10px 15px;
`

const StyledCategoryWrapper = styled(Wrapper)`
    border-bottom: 1px solid ${BACKGROUNDS.grey};
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

const GamePopup = (props) => {
    let { data: game } = props
    const [isFavorite, setIsFavorite] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [favoriteGames, setFavoriteGames] = useState([])
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const [clickedOutside, setClickedOutside] = useState(false)

    const wrapperRef = useRef()

    useOutsideDetector(wrapperRef, () => setClickedOutside(true))

    const history = useHistory()

    useEffect(() => {
        const subscription = GamesService.getFavoriteGames().subscribe(
            (res) => {
                setFavoriteGames(res)
                if (res && res.length) {
                    setIsFavorite(checkFavorite(res, game.game_id))
                }
            }
        )
        const userSub = UserService.getUserLoggedStatus().subscribe((res) => {
            setIsUserLoggedIn(res)
        })
        return () => {
            subscription.unsubscribe()
            userSub.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            clickedOutside && cleanUrl()
        },
        [clickedOutside]
    )

    const checkFavorite = (favorite, game_id) => {
        return favorite && favorite.length
            ? favorite.map((e) => e.game_id).indexOf(game_id) >= 0
            : false
    }

    const gameLike = (e) => {
        setDisabled(true)
        e.stopPropagation()
        if (isUserLoggedIn) {
            GamesService.gameLike(game.game_id)
                .then(() => {
                    setDisabled(false)
                    GamesService.fetchFavorite()
                })
                .catch((error) => {
                    setDisabled(false)
                    NotificationConductor.error(error.toString())
                })
        } else {
            setDisabled(false)
            NotificationConductor.error(t.loginForFavorites)
        }
    }

    const gameUnLike = (e) => {
        setDisabled(true)
        e.stopPropagation()
        if (isUserLoggedIn) {
            GamesService.gameUnLike(game.game_id)
                .then(() => {
                    setDisabled(false)
                    GamesService.fetchFavorite()
                })
                .catch((error) => {
                    setDisabled(false)
                    NotificationConductor.error(error.toString())
                })
        } else {
            setDisabled(false)
            NotificationConductor.error(t.loginForFavorites)
        }
    }

    const closeModal = (modalName = '') => {
        cleanUrl()
        isFunction(props.close)
            ? props.close(modalName)
            : props.actions.hideModal(modalName)
    }

    const cleanUrl = () => {
        const backUrl = props.data.backUrl ? props.data.backUrl : '/velkommen'

        window.history.pushState('', '', backUrl)
    }

    const gameStart = (mode = 'fun') => {
        GamesService.pushGameToRecentlyPlayed(game)
        closeModal()

        GamesService.checkLoginBeforeStart(game, history, mode, isUserLoggedIn)
    }

    const filterPropertyName = (name) => {
        const el = SMART_FILTERS.find((f) => f.name === name)
        return el ? el.label : null
    }

    const openCategoryPage = (key, value) => {
        closeModal()
        history.push(`/sort?key=${key}&value=${value}`)
    }

    return (
        <StyledGamePopupWrapper ref={wrapperRef}>
            <Wrapper padding="10px 20px">
                <CloseModalButton
                    top="15px"
                    right="10px"
                    hideModal={() => {
                        closeModal('GAME_POPUP')
                    }}
                    color="grey"
                />
                <Headline5>{game.name}</Headline5>
            </Wrapper>
            <GameCardWrapper>
                <CardImage hoverEffect={false} game={game} />
                {game.jackpot_amount_formatted && (
                    <JackpotWrapper>
                        <Text white>{game.jackpot_amount_formatted} kr.</Text>
                    </JackpotWrapper>
                )}
            </GameCardWrapper>
            <StyledPopupContent>
                <GameInfoWrapper>
                    <MainActionsRow
                        onlyForMoney={
                            game.onlyForMoney ||
                            game.category === 'liveint' ||
                            isUserLoggedIn
                        }
                        disabled={disabled}
                        isFavorite={isFavorite}
                        gameLike={gameLike}
                        gameUnLike={gameUnLike}
                        startGame={() => {
                            gameStart('money')
                        }}
                        tryGame={gameStart}
                    />
                    <ProviderInfo
                        logoSrc={gameLogoTemp}
                        gameName={game.name}
                        info={game.info}
                    />
                    <InfoList {...game} />
                    <Wrapper padding="0">
                        {game.features &&
                        game.features.length &&
                        game.features[0] !== '' ? (
                            <Wrapper padding="0">
                                <Headline5 width="100%">Funktioner</Headline5>
                                <StyledCategoryWrapper padding="0">
                                    {game.features.map(
                                        (el) =>
                                            el !== '' && (
                                                <Button
                                                    margin="5px 0"
                                                    padding="7px 0"
                                                    width="48%"
                                                    key={el}
                                                    accent
                                                    onClick={() => {
                                                        openCategoryPage(
                                                            'features',
                                                            el
                                                        )
                                                    }}
                                                >
                                                    {filterPropertyName(el)}
                                                </Button>
                                            )
                                    )}
                                </StyledCategoryWrapper>
                            </Wrapper>
                        ) : null}
                        {game.theme && game.theme !== '' ? (
                            <Wrapper padding="0">
                                <Headline5 width="100%">Tema</Headline5>
                                <StyledCategoryWrapper padding="0">
                                    {game.theme.split('|').map((el) => (
                                        <Button
                                            key={el}
                                            margin="5px 0"
                                            padding="7px 0"
                                            width="48%"
                                            accent
                                            onClick={() => {
                                                openCategoryPage('theme', el)
                                            }}
                                        >
                                            {filterPropertyName(el)}
                                        </Button>
                                    ))}
                                </StyledCategoryWrapper>
                            </Wrapper>
                        ) : null}
                    </Wrapper>
                </GameInfoWrapper>
            </StyledPopupContent>
        </StyledGamePopupWrapper>
    )
}

export default GamePopup
