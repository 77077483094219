import React, { useEffect, useState } from 'react'
import Wrapper from 'ui/Wrapper'
import styled from 'styled-components'
import { useHistory, useRouteMatch, withRouter } from 'react-router-dom'
import GamesService from 'services/GamesService'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from 'ui/Loader'
import { BACKGROUNDS } from 'themes'
import ResponsiveGrid from 'features/ResponsiveGrid'
import device from 'utils/mediaQueries'
import GridControls from 'features/GridControls'
import UserService from 'services/UserService'
import { slideUp } from 'utils/ui'
import PageBanner from 'ui/PageBanner'
import { ProvidersBanner } from 'configs/ui'
import { WrapperOverlay } from 'ui/WrapperOverlay'
import { skipWhile } from 'rxjs/operators'

const FlexWrapper = styled(Wrapper)`
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1500px;
    margin: 0 auto;
    width: 100%;
    @media screen and ${device.xxl} {
        max-width: 1000px;
    }
    @media screen and ${device.xl} {
        max-width: 900px;
    }
`

const Providers = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const [gamesToMap, setGamesToMap] = useState([])
    const [gamesToMapAll, setGamesToMapAll] = useState([])
    const [gridType, setGridType] = useState('dynamic')
    const [title, setTitle] = useState(null)
    const [isUserLoggedIn, setIUserLoggedIn] = useState(false)
    const [bannerData, setBannerData] = useState(null)

    const getIterationLength = () => {
        let type = localStorage.getItem('gridType') || gridType
        return GamesService.getIterationLength(type)
    }

    useEffect(
        () => {
            slideUp()
            const savedGridType = localStorage.getItem('gridType')
            if (savedGridType) {
                setGridType(savedGridType)
            }
            const userSub = UserService.getUserLoggedStatus().subscribe(
                (res) => {
                    setIUserLoggedIn(res)
                }
            )
            const categorizedSub = GamesService.getCategorizedGames().subscribe(
                (games) => {
                    setGamesToMapAll(games)
                    setGamesToMap(games.slice(0, getIterationLength()))
                }
            )
            const fetchGames = (subproviderName) => {
                const filterName =
                    subproviderName === 'scientific'
                        ? 'scientific,gdm'
                        : subproviderName
                GamesService.searchGames(`filter[subprovider]=${filterName}`)
                    .then((res) => {
                        if (res && res.data) {
                            const preparedGames = GamesService.prepareJackpotsGame(
                                res.data
                            )
                            setGamesToMapAll(preparedGames)
                            setGamesToMap(
                                preparedGames.slice(0, getIterationLength())
                            )
                        }
                    })
                    .catch(console.error)
            }

            GamesService.getProvidersGames()
                .pipe(skipWhile((res) => res === null))
                .subscribe((res) => {
                    if (res) {
                        let provider = res.find(
                            (el) => el.name === match.params.provider
                        )
                        if (provider) {
                            setTitle(provider.title)

                            provider.name &&
                                ProvidersBanner[provider.name] &&
                                setBannerData(ProvidersBanner[provider.name])

                            fetchGames(match.params.provider)
                        } else {
                            console.error('Provider is not present in the list')
                            history.push('/404')
                        }
                    }
                })
            return () => {
                userSub.unsubscribe()
                categorizedSub.unsubscribe()
            }
        },
        [match.params.provider]
    )

    const loadFunc = () => {
        setGamesToMap(
            gamesToMapAll.slice(0, gamesToMap.length + getIterationLength())
        )
    }
    const changeGridType = (type) => {
        localStorage.setItem('gridType', type)
        setGridType(type)
    }
    return (
        <WrapperOverlay overlayOffset={250} margin="0" padding="0 10px 10px">
            {bannerData && (
                <PageBanner
                    bgImageUrl={bannerData.bgImage}
                    image={bannerData.image}
                    logo={bannerData.logo}
                    styles={bannerData.styles}
                />
            )}
            <Wrapper padding="0" width="100%">
                {gamesToMap.length ? (
                    <FlexWrapper>
                        <GridControls
                            category={title}
                            gamesToMapAll={gamesToMapAll}
                            setGamesToMapAll={setGamesToMapAll}
                            setGamesToMap={setGamesToMap}
                            setGridType={changeGridType}
                            gridType={gridType}
                            isUserLoggedIn={isUserLoggedIn}
                        />
                        <InfiniteScroll
                            style={{ width: '100%' }}
                            loadMore={loadFunc}
                            pageStart={0}
                            threshold={50}
                            hasMore={gamesToMap.length < gamesToMapAll.length}
                            loader={
                                <Loader
                                    key={'loader'}
                                    color={BACKGROUNDS.primary}
                                    margin="20px auto 0"
                                />
                            }
                        >
                            <ResponsiveGrid
                                key={'grid'}
                                gridType={gridType}
                                games={gamesToMap}
                                bigStar
                            />
                        </InfiniteScroll>
                    </FlexWrapper>
                ) : null}
                {!gamesToMap.length ? (
                    <Loader color={BACKGROUNDS.primary} margin="20px auto 0" />
                ) : null}
            </Wrapper>
        </WrapperOverlay>
    )
}

export default withRouter(Providers)
