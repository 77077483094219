import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import getTexts from 'utils/localization'
import { providersData } from 'configs/ui'
import LabelIcon from 'ui/Labelcon'
import HeadingWithBadge from 'ui/HeadingWithBadge'
import {
    Controls,
    GamesCount,
    ImageOverlay,
    InfoWrapper,
    LabelWrapper,
    PageWrapper,
    ProviderCard,
    ProviderImage,
    ProviderLogo,
    ProviderWrapper,
    StyledWrapper,
} from './styled'

const liveLabelProps = {
    background: '#DB1C1B',
    textColor: '#fff',
    text: 'LIVE',
}

const t = getTexts()

const ProvidersGrid = ({ providers = [] }) => {
    const history = useHistory()

    const handleClick = (provider) => {
        history.push({
            pathname: `/providers/${provider.name}`,
            state: { provider },
        })
    }

    return (
        <PageWrapper>
            <Controls>
                <HeadingWithBadge size="x5" bold>
                    {t.providers}
                </HeadingWithBadge>
            </Controls>
            <StyledWrapper>
                {providers.length &&
                    providers.map((provider, index) => {
                        return (
                            <ProviderWrapper key={index}>
                                <ProviderCard>
                                    <ImageOverlay
                                        onClick={() => handleClick(provider)}
                                    >
                                        {provider.isLive && (
                                            <LabelWrapper>
                                                <LabelIcon
                                                    {...liveLabelProps}
                                                />
                                            </LabelWrapper>
                                        )}
                                        <ProviderImage
                                            src={provider.verticalImage}
                                            alt={`provider-image-${provider.name ||
                                                ''}`}
                                        />
                                    </ImageOverlay>
                                    <InfoWrapper>
                                        <ProviderLogo
                                            height={provider.height || '40px'}
                                            width={provider.width || '100%'}
                                            color="white"
                                            src={provider.logo || ''}
                                            alt={`provider-logo-${provider.label ||
                                                ''}`}
                                        />
                                        <GamesCount>
                                            {provider.games &&
                                            provider.games.count
                                                ? provider.games.count
                                                : 'XXX'}{' '}
                                            spil
                                        </GamesCount>
                                    </InfoWrapper>
                                </ProviderCard>
                            </ProviderWrapper>
                        )
                    })}
            </StyledWrapper>
        </PageWrapper>
    )
}

export default ProvidersGrid
