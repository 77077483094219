import React, { useEffect, useState } from 'react'
import { BACKGROUNDS } from 'themes'
import styled from 'styled-components'
import ModalTopBar from 'ui/ModalTopBar'
import Box from 'ui/Box'
import NavRow from 'ui/NavRow'
import { ModalService } from 'services/ModalService'
import arrowRight from 'icons/right.svg'
import transactions from 'icons/transactions.svg'
import spilhistory from 'icons/update.svg'
import { isFunction } from 'utils/object-helper'
import Text from 'ui/Text'
import IntercomAdapter from 'common/adapters/IntercomAdapter'
import { UserHistory as UserHistoryComponent } from '@it25syv/25syv-ui'
import UserService from 'services/UserService'

const ModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const UserHistory = (props) => {
    const [showMore, setShowMore] = useState(false)
    const [transactions, setTransactions] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTabData, setActiveTabData] = useState({})
    const [configs, setConfigs] = useState({
        dateFrom: null,
        dateTo: null,
        rowCount: 0,
    })
    const [date, setDate] = useState({
        dateFrom: configs.dateFrom,
        dateTo: configs.dateTo,
    })

    useEffect(
        () => {
            if (date.dateTo !== null) {
                let subscription
                const groupKey = activeTabData.groupKey
                setLoading(true)

                UserService.fetchTransactions(
                    groupKey,
                    date.dateFrom,
                    date.dateTo,
                    0,
                    configs.rowCount
                )

                subscription = UserService.subjectsMap[groupKey].subscribe(
                    (data) => {
                        setTransactions(data.transactions)
                        setShowMore(data.count > data.transactions.length)
                        setLoading(false)
                    }
                )

                return () => {
                    if (subscription) {
                        subscription.unsubscribe()
                    }
                }
            }
        },
        [date, activeTabData]
    )

    useEffect(() => {
        const subscription = UserService.getTransactionDate.subscribe(
            ({ dateFrom, dateTo }) => {
                setDate({ dateFrom, dateTo })
            }
        )

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    useEffect(
        () => {
            if (configs.dateFrom && configs.dateTo) {
                UserService.setTransactionDate({
                    dateFrom: configs.dateFrom,
                    dateTo: configs.dateTo,
                })
            }
        },
        [configs]
    )

    const setDefaultDate = (date) => {
        setDate(date)
        UserService.setTransactionDate(date)
    }

    const loadMoreHandler = () => {
        const groupKey = activeTabData.groupKey
        const offset = Math.floor(transactions.length / configs.rowCount)
        setLoading(true)
        UserService.fetchTransactions(
            groupKey,
            date.dateFrom,
            date.dateTo,
            offset,
            configs.rowCount
        )
    }

    const closeModal = (modalName = '') => {
        isFunction(props.close)
            ? props.close(modalName)
            : ModalService.closeModal(modalName)
    }

    return (
        <ModalWrapper background={BACKGROUNDS.light} height={'100%'}>
            <ModalTopBar title={'Historik'} customClose={closeModal} />

            <UserHistoryComponent
                currentTheme={'dark'}
                transactions={transactions}
                loading={loading}
                showMore={showMore}
                loadMoreHandler={() => loadMoreHandler()}
                setTab={setActiveTabData}
                setInitialConfig={setConfigs}
                currentDate={date}
                showDetails={(data) => {
                    ModalService.showModal('TRANSACTION_INFO', data)
                }}
                openChooseDate={() =>
                    ModalService.showModal('TRANSACTION_CHOOSE_DATE')
                }
                resetDateHandler={(defaultDate) => setDefaultDate(defaultDate)}
            />
        </ModalWrapper>
    )
}

export default UserHistory
