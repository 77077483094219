import { BehaviorSubject, Subject } from 'rxjs'
import NotificationConductor from 'common/conductors/NotificationConductor'
import { getBonus } from 'common/adapters/ProviderAdapter'
import UserAdapter from 'common/adapters/UserAdapter'

export const resetBonusesStatus = new BehaviorSubject(false)

export const setResetBonusesStatus = (reset) => {
    resetBonusesStatus.next(reset)
}

export const getResetBonusesStatus = () => resetBonusesStatus.asObservable()

const bonusList = new Subject()

const activeBonusList = new BehaviorSubject([])
const activeBonus = new Subject()

export const setBonusList = () => {
    getBonus()
        .then(({ data }) => {
            try {
                if (data) {
                    bonusList.next({
                        totalBonus: data.amount,
                        rollover: data.rollOver,
                        expirationDate: data.expire,
                        gameId: data.gameId,
                    })
                } else {
                    bonusList.next({})
                }
            } catch (e) {
                console.error(e)
            }
        })
        .catch((error) => NotificationConductor.error(error.toString()))
}

export const getBonusList = () => bonusList.asObservable()

const setActiveBonusList = () => {
    UserAdapter.getActiveBonusList()
        .then((data) => {
            activeBonusList.next(data)
        })
        .catch((error) => {
            NotificationConductor.error(error.toString())
            activeBonusList.next([])
        })
}

const getActiveBonusList = () => activeBonusList.asObservable()

const removeActiveBonus = () => {
    setActiveBonusList()
    activeBonus.next({})
}

export default {
    setActiveBonusList,
    getActiveBonusList,
    removeActiveBonus,
}
